import {
  AddTask,
  Delete,
  Edit,
  Sync,
  ToggleOff,
  ToggleOn,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Action({ config }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const params = useParams();
  const {
    Icon,
    sx,
    defaultSx,
    popup,
    popupOptions,
    queryState = {},
    mutationState = {},
    ...rest
  } = config;

  const { data } = useQuery({
    ...(config?.queryOptionFn && {
      ...config.queryOptionFn({ navigate, params, queryState }),
    }),
    enabled: !!config?.queryOptionFn,
  });
  const { mutate, isPending } = useMutation({
    ...(config?.mutationOptionFn && {
      ...config.mutationOptionFn({ navigate, params, mutationState }),
    }),
  });

  function handleClick() {
    if (popup) {
      handleClickOpen();
      return;
    }
    if (config?.onClick) {
      if (config?.queryOptionFn) {
        config.onClick(data);
      }
      if (config?.mutationOptionFn) {
        config.onClick(mutate);
      }
      config.onClick();
    }
  }

  return (
    <>
      <IconButton
        size="small"
        sx={{ ...defaultSx, ...sx }}
        {...rest}
        onClick={handleClick}
      >
        <Icon />
      </IconButton>
      {popup && (
        <PopupComponent
          open={open}
          handleClose={handleClose}
          config={config}
          mutate={mutate}
          isPending={isPending}
          popupOptions={popupOptions}
        />
      )}
    </>
  );
}

function PopupComponent({
  open,
  handleClose,
  config,
  mutate,
  isPending,
  popupOptions,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{popupOptions?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description ">
          {popupOptions?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{popupOptions?.cancel}</Button>
        <Button
          onClick={async () => {
            if (config?.onClick) {
              if (config?.mutationOptionFn) {
                await config.onClick(mutate);
              } else {
                await config.onClick();
              }
            }
            handleClose();
          }}
          disabled={isPending}
          color={popupOptions?.color || config?.color}
          variant="contained"
        >
          {popupOptions?.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function TableActions({ actions = {} }) {
  const configs = {
    edit: {
      Icon: Edit,
      color: "primary",
    },
    delete: {
      Icon: Delete,
      color: "error",
      popup: true,
      popupOptions: {
        ok: "Delete",
        cancel: "Cancel",
        description: "Are you sure you want to delete?",
        title: "Delete",
      },
    },
    view: {
      Icon: Visibility,
      color: "info",
    },
    activate: {
      Icon: ToggleOff,
      color: "grey[300]",
      popup: true,
      popupOptions: {
        ok: "Activate",
        cancel: "Cancel",
        description: "Are you sure you want to activate?",
        title: "Activate",
        color: "success",
      },
    },
    deActivate: {
      Icon: ToggleOn,
      color: "success",
      popup: true,
      popupOptions: {
        ok: "Deactivate",
        cancel: "Cancel",
        description: "Are you sure you want to deactivate?",
        title: "Deactivate",
        color: "error",
      },
    },
    verify: {
      Icon: AddTask,
      color: "info",
    },
    sync: {
      Icon: Sync,
      color: "info",
    },
  };
  return (
    <ButtonGroup>
      {Object.entries(actions).map(([key, value]) => (
        <Action key={key} config={{ ...configs[key], ...value }} />
      ))}
    </ButtonGroup>
  );
}
