import { ADToBS } from "@kumarad9/nepalicalendar";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { readAttendee, readClasses, readUsers } from "../../api";
import { getIndividualReportQueryOptions } from "../../api/queryOptions";
import Export from "../../components/Buttons/Export";
import FormElement from "../../components/form";
import List from "../../layouts/List";
export default function IndividualReport() {
  const { t } = useTranslation();
  const [role, setRole] = useState(null);
  const [grade, setGrade] = useState(null);

  const { data: classData, isLoading: isClassLoading } = useQuery({
    queryKey: ["classes"],
    queryFn: readClasses(),
  });

  const { data: usersData, isLoading: isUsersLoading } = useQuery({
    queryKey: ["users", role],
    queryFn: readUsers(role),
  });

  const { data: classStudents, isLoading: isStudentLoading } = useQuery({
    queryKey: ["attendee", role, grade],
    queryFn: readAttendee(role, "?class=" + grade),
    enabled: !!grade,
  });

  const defaultFilters = {
    attendee: null,
    from: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
    to: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: t("date"),
      },
      {
        accessorKey: "status",
        header: t("status"),
      },
      {
        accessorKey: "checkin",
        header: t("checkIn"),
      },
      {
        accessorKey: "checkout",
        header: t("checkOut"),
      },
    ],
    [t]
  );

  let query = useMemo(() => {
    return Object.entries(filters)
      .filter(([key, value]) => {
        if (key === "attendee") return false;
        return value !== null;
      })
      .reduce((acc, [key, value]) => {
        return acc === "?" ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
      }, "?");
  }, [filters]);

  const FilterComponents = [
    <FormElement.Select
      key="role"
      id="role"
      label={t("role")}
      placeholder={"search attendee name or userid"}
      options={[
        { label: "Student", value: "student" },
        { label: "Teacher", value: "teacher" },
        { label: "Employee", value: "employee" },
      ]}
      onChange={(value) => setRole(value.value)}
    />,
    ...(role === "student"
      ? [
          <FormElement.Select
            key="grade"
            id="grade"
            label={t("grade")}
            placeholder={"Grade"}
            options={classData?.data?.data?.results?.map((grade) => ({
              label: grade?.name,
              value: grade?.id,
            }))}
            isLoading={isClassLoading}
            onChange={(value) => {
              setGrade(value.value);
            }}
          />,
        ]
      : []),
    <FormElement.Select
      key="attendee"
      id="attendee"
      label={t("Attendee Name/User Id")}
      placeholder={"search attendee name or userid"}
      options={
        grade
          ? classStudents?.data?.data?.results?.map((student) => ({
              label:
                student?.first_name +
                " " +
                student?.last_name +
                " - " +
                student?.userId,
              value: student?.id,
            }))
          : usersData?.data?.data?.results?.map((user) => ({
              label:
                user?.first_name + " " + user?.last_name + " - " + user?.userId,
              value: user?.id,
            }))
      }
      isLoading={isUsersLoading || isStudentLoading}
      value={filters?.attendee}
      onChange={(value) =>
        setFilters((prev) => ({ ...prev, attendee: value.value }))
      }
    />,
    <FormElement.Date
      key="from"
      id="from"
      label={t("dateFrom")}
      onDateChange={(value) => {
        if (value) {
          setFilters((prev) => ({ ...prev, from: value?.replace(/\//g, "-") }));
        }
      }}
    />,
    <FormElement.Date
      key="to"
      id="to"
      label={t("dateTo")}
      onDateChange={(value) =>
        setFilters((prev) => ({ ...prev, to: value?.replace(/\//g, "-") }))
      }
    />,
  ];

  return (
    <Box p={2}>
      <List
        otherButtons={[
          <Export
            disabled={!filters?.attendee}
            url={`/report/attendance/${filters.attendee}/individual/excel${
              query || ""
            }`}
          />,
        ]}
        tableSize="small"
        filters={FilterComponents}
        clearFilter={() => setFilters(defaultFilters)}
        title="individualReport"
        columns={columns}
        queryOptionFn={getIndividualReportQueryOptions}
        queryState={{
          enabled: !!filters?.attendee,
          id: filters.attendee,
          query,
        }}
        transformData={(data) => {
          return data?.data?.data?.results?.days;
        }}
      />
    </Box>
  );
}
