import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { toast } from "react-toastify";
import i18n from "./config/i18n";
import theme from "./config/theme";
import { AppProvider } from "./contexts/AppContext";
import router from "./routes";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
    mutations: {
      onError: (error) => {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
          return;
        }
        toast.error(error?.message);
      },
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <I18nextProvider i18n={i18n} defaultNS={"translations"}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </I18nextProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}
