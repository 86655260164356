import { Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readOrganizations, readUser } from "../../../api";
import { getUserMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import { userSchema } from "../../../validations/schema";

function UserFormElement({
  register,
  formState: { errors },
  editId,
  setValue,
  watch,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["users", editId],
    queryFn: readUser(editId),
    enabled: !!editId,
  });

  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("firstName")}
              register={register}
              required
              id="first_name"
              error={errors?.["first_name"]?.message}
              defaultValue={data?.data?.data?.first_name}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("lastName")}
              register={register}
              required
              id="last_name"
              error={errors?.["last_name"]?.message}
              defaultValue={data?.data?.data?.last_name}
            />
          </Grid2>

          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required
              type="email"
              id="email"
              label={t("email")}
              error={errors?.["email"]?.message}
              defaultValue={data?.data?.data?.email}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required
              type="tel"
              id="phone"
              label={t("phone")}
              error={errors?.["phone"]?.message}
              defaultValue={data?.data?.data?.phone}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Select
              required
              options={[
                { value: "admin", label: "Admin" },
                { value: "organizationAdmin", label: "Organization Admin" },
              ]}
              setValue={setValue}
              id="role"
              label={t("role")}
              error={errors?.["role"]?.message}
            />
          </Grid2>
          {watch("role")?.value === "organizationAdmin" && (
            <Grid2 sm={4}>
              <FormElement.Select
                required
                id="organization"
                label={t("organization")}
                setValue={setValue}
                options={orgData?.data?.data?.results?.map((org) => ({
                  value: org?.id,
                  label: org?.name,
                }))}
                error={errors?.["organization"]?.message}
                defaultValue={data?.data?.data?.organization}
              />
            </Grid2>
          )}
          <Grid2 sm={12} py={2}>
            <Typography variant="body1" fontWeight="bold">
              {t("loginDetails")}
            </Typography>
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required
              id="username"
              label={t("username")}
              error={errors?.["username"]?.message}
              defaultValue={data?.data?.data?.username}
              disabled={!!editId}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required={!editId}
              type="password"
              id="password"
              label={t("password")}
              error={errors?.["password"]?.message}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data) {
  let body = { ...data };
  !data.password && delete body.password;
  if (data?.organization) {
    body.organization = data?.organization?.value;
  }
  if (data?.role) {
    body.role = data?.role?.value;
  }
  return body;
}

const UserForm = withHookForm({
  title: "createUser",
  submitText: "createUser",
  validationSchema: userSchema(),
  mutationOptionFn: getUserMutationOptions,
  transformData,
})(UserFormElement);

const UserUpdateForm = withHookForm({
  title: "updateUser",
  submitText: "updateUser",
  validationSchema: userSchema(),
  mutationOptionFn: getUserMutationOptions,
  transformData,
})(UserFormElement);

export { UserUpdateForm };

export default UserForm;
