/**
 * @module hocs/withHookForm
 */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft, Loop } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useUrlQuery from "../hooks/useUrlQuery";

/**
 * @function withHookForm
 * @memberof module:hocs/withHookForm
 * @description A higher order component that wraps a component with hook form
 * @param {object} configs
 * @param {string} configs.title - Title of the form
 * @param {string} configs.submitText - Text of the submit button
 * @param {string} configs.cancelText - Text of the cancel button
 * @param {function} configs.mutationOptionFn - Mutation option function
 * @param {object} configs.validationSchema - Validation schema
 * @param {function} configs.transformData - Transform data function
 * @param {object} configs.mutationState - Mutation state
 * @returns {WrapperFunction} function that accepts component without hook form
 */
export default function withHookForm({
  title,
  submitText = "submit",
  cancelText = "cancel",
  mutationOptionFn,
  validationSchema,
  transformData = null,
  mutationState = {},
}) {
  /**
   * @function WrapperFunction
   * @param {function} FormComponent Component to wrap with hook form
   * @returns {FormComponentWithHookForm} Components returned with hook form
   */
  return (Component) => {
    /**
     * @function FormComponentWithHookForm
     * @param {object} props - Component props
     */
    return (props) => {
      const navigate = useNavigate();
      const { t } = useTranslation();
      const {
        register,
        unregister,
        formState,
        watch,
        handleSubmit,
        reset,
        resetField,
        setError,
        clearErrors,
        setValue,
        setFocus,
        getValues,
        getFieldState,
        control,
        trigger,
      } = useForm({
        ...(validationSchema && { resolver: yupResolver(validationSchema) }),
      });
      const params = useParams();

      const queries = useUrlQuery();

      const { mutate, isPending } = useMutation({
        ...(mutationOptionFn && { ...mutationOptionFn({ navigate, params }) }),
        enable: !!mutationOptionFn,
      });

      function onSubmit(data) {
        let body = { ...data };
        if (transformData) {
          body = transformData(data, params, queries);
        }
        mutationOptionFn &&
          mutate({
            ...(params?.id && {
              id: params?.id,
              mutationMode: "patch",
              ...mutationState,
            }),
            ...body,
          });
      }

      return (
        <Box p={2}>
          <Stack>
            <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft color="primary" />
              </IconButton>
              <Typography variant="h5" color="primary">
                {t(title)}
              </Typography>
            </Stack>
          </Stack>
          <Box component={Paper} p={2}>
            <Component
              {...props}
              {...{
                title,
                register,
                unregister,
                formState,
                watch,
                reset,
                resetField,
                setError,
                clearErrors,
                setValue,
                setFocus,
                getValues,
                getFieldState,
                control,
                trigger,
                editId: params?.id,
              }}
            />
          </Box>

          <Stack component={Paper} direction={"row"} p={3} columnGap={2}>
            <Button
              startIcon={isPending && <Loop className="tw-animate-spin" />}
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
            >
              {t(submitText)}
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={isPending && <Loop className="tw-animate-spin" />}
              disabled={isPending}
            >
              {t(cancelText)}
            </Button>
          </Stack>
        </Box>
      );
    };
  };
}
