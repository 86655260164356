import NepaliCalendar from "@kumarad9/nepalicalendar";

import { Box, TextField } from "@mui/material";

export default function DateComponent({
  setValue,
  setDate,
  id,
  onDateChange,
  ...props
}) {
  return (
    <Box position={"relative"}>
      <NepaliCalendar
        as={TextField}
        size="small"
        refName="inputRef"
        showDefaultDate
        onChange={(name, dateInMilli, bsDate, _adDate) => {
          setDate && setDate(bsDate);
          onDateChange && onDateChange(bsDate);
          setValue && setValue(id, bsDate);
        }}
        {...props}
      />
    </Box>
  );
}
