import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readOrganizations, readShift } from "../../../api";
import { getShiftMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import useApp from "../../../hooks/useApp";
import { shiftSchema } from "../../../validations/schema";

function ShiftFormElement({
  register,
  formState: { errors },
  editId,
  setValue,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["shift", editId],
    queryFn: readShift(editId),
    enabled: !!editId,
  });
  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });
  const app = useApp();

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("title")}
              register={register}
              required
              id="title"
              error={errors?.["title"]?.message}
              defaultValue={data?.data?.data?.title}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required
              id="start_time"
              type="time"
              label={t("startTime")}
              error={errors?.["start_time"]?.message}
              defaultValue={data?.data?.data?.start_time || "09:00"}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              required
              label={t("endTime")}
              register={register}
              id="end_time"
              type="time"
              error={errors?.["end_time"]?.message}
              defaultValue={data?.data?.data?.end_time || "18:00"}
            />
          </Grid2>
          {app?.user?.role === "admin" && (
            <Grid2 sm={4}>
              <FormElement.Select
                required
                id="organization"
                label={t("organization")}
                setValue={setValue}
                options={orgData?.data?.data?.results?.map((org) => ({
                  value: org?.id,
                  label: org?.name,
                }))}
                error={errors?.["organization"]?.message}
                defaultValue={data?.data?.data?.organization}
              />
            </Grid2>
          )}
        </Grid2>
      )}
    </Stack>
  );
}
function transformData(data) {
  let body = { ...data };
  if (data.organization) {
    body.organization = data?.organization?.value;
  }
  return body;
}

const ShiftForm = withHookForm({
  title: "createShift",
  submitText: "createShift",
  validationSchema: shiftSchema(),
  mutationOptionFn: getShiftMutationOptions,
  transformData,
})(ShiftFormElement);

const ShiftUpdateForm = withHookForm({
  title: "updateShift",
  submitText: "updateShift",
  validationSchema: shiftSchema(),
  mutationOptionFn: getShiftMutationOptions,
  transformData,
})(ShiftFormElement);

export { ShiftUpdateForm };

export default ShiftForm;
