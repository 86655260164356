import { ADToBS } from "@kumarad9/nepalicalendar";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { readClasses } from "../../api";
import { getAbsentListQueryOptions } from "../../api/queryOptions";
import Export from "../../components/Buttons/Export";
import FormElement from "../../components/form";
import List from "../../layouts/List";
export default function AbsentList() {
  const { t } = useTranslation();

  const { data: classData, isLoading: isClassLoading } = useQuery({
    queryKey: ["classes"],
    queryFn: readClasses(),
  });

  const defaultFilters = {
    role: null,
    grade: null,
    from: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
    to: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const columns = useMemo(
    () => [
      {
        accessorKey: "roll_no",
        header: t("rollNo"),
      },
      {
        accessorKey: "gradeData.name",
        header: t("grade"),
      },
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: t("name"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
    ],
    [t]
  );

  let query = useMemo(() => {
    return Object.entries(filters)
      .filter(([key, value]) => {
        if (key === "attendee") return false;
        return value !== null;
      })
      .reduce((acc, [key, value]) => {
        return acc === "?" ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
      }, "?");
  }, [filters]);

  const FilterComponents = [
    <FormElement.Select
      key="role"
      id="role"
      label={t("role")}
      placeholder={"search attendee name or userid"}
      options={[
        { label: "Student", value: "student" },
        { label: "Teacher", value: "teacher" },
        { label: "Employee", value: "employee" },
      ]}
      onChange={(value) =>
        setFilters((prev) => ({ ...prev, role: value.value }))
      }
    />,
    ...(filters.role === "student"
      ? [
          <FormElement.Select
            key="grade"
            id="grade"
            label={t("grade")}
            placeholder={"Grade"}
            options={classData?.data?.data?.results?.map((grade) => ({
              label: grade?.name,
              value: grade?.id,
            }))}
            isLoading={isClassLoading}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, grade: value.value }));
            }}
          />,
        ]
      : []),

    <FormElement.Date
      key="from"
      id="from"
      label={t("from")}
      onDateChange={(value) => {
        if (value) {
          setFilters((prev) => ({ ...prev, from: value?.replace(/\//g, "-") }));
        }
      }}
    />,
    <FormElement.Date
      key="to"
      id="to"
      label={t("to")}
      onDateChange={(value) =>
        setFilters((prev) => ({ ...prev, to: value?.replace(/\//g, "-") }))
      }
    />,
  ];

  return (
    <Box p={2}>
      <List
        otherButtons={[<Export url={`/report/absence/excel${query || ""}`} />]}
        tableSize="small"
        filters={FilterComponents}
        clearFilter={() => setFilters(defaultFilters)}
        title="absentList"
        columns={columns}
        queryOptionFn={getAbsentListQueryOptions}
        queryState={{
          // enabled: !!filters?.attendee,
          id: filters.attendee,
          query,
        }}
        transformData={(data) => {
          return data?.data?.data?.results;
        }}
      />
    </Box>
  );
}
