import { Button, ButtonGroup } from "@mui/material";

export default function ButtonTab({ state, setState, buttons = {} }) {
  return (
    <ButtonGroup>
      {Object.entries(buttons).map(([key, value]) => (
        <Button
          key={key}
          variant={state === key ? "contained" : "outlined"}
          onClick={() => setState(key)}
        >
          {value}
        </Button>
      ))}
    </ButtonGroup>
  );
}
