import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#005C78",
    },
    secondary: {
      main: "#E88D67",
    },
    background: {
      default: "#fcfaf1",
      paper: "#f3f7ec",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#78909c",
          paddingBlock: "12px",
          "&.Mui-selected": {
            color: "#005C78",
            backgroundColor: "#f3f7ec",
            borderRadius: "8px",
            boxShadow: "1px 2px 1px -1px #005C7844",
          },
          "&.Mui-selected  .MuiSvgIcon-root": {
            color: "#005C78",
          },
        },
      },
    },
  },
});

export default theme;
