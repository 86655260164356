import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import axiosInstance from "../../api";

export default function Export({ type = "excel", url = null, ...props }) {
  async function onClick() {
    try {
      if (!url) return;
      let response = await axiosInstance.get(url);
      let downloadURL = await response?.data?.data?.url;
      let a = document.createElement("a");
      a.href = downloadURL;
      a.target = "_blank";
      a.download = `${type}.xlsx`;
      a.click();
    } catch (error) {
      toast.error(error?.message);
    }
  }

  return (
    <Button endIcon={<Download />} onClick={onClick} {...props}>
      Export to {type}
    </Button>
  );
}
