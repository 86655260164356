import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";

export default function DashboardLayout() {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <Stack
      bgcolor="background.default"
      sx={{ height: "100vh" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box>
        <Typography variant="h3" mb={4} color={"error.main"}>
          Error {error.status}: {error.statusText}
        </Typography>
        <Typography variant="h5" color={"grey.500"}>
          {error?.data}
        </Typography>
        {Object.entries(error?.error).map(([key, value]) => (
          <Typography key={key} variant="body1">
            {key}: {value}
          </Typography>
        ))}
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          size="large"
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    </Stack>
  );
}
