import { Close, CloudUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileComponent({ id, label, setValue, ...props }) {
  const [files, setFiles] = useState([]);

  function onChange(e) {
    if (e.target.files.length > 0) {
      setFiles([...e.target.files]);
      setValue && setValue(id, [...e.target.files]);
      props.onChange && props.onChange(e.target.files);
    }
  }

  return (
    <Box position={"relative"}>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUpload />}
        sx={{ width: "100%" }}
      >
        {label || "Upload file"}
        <VisuallyHiddenInput
          type="file"
          {...props}
          onChange={onChange}
          multiple
        />
      </Button>
      <ImageList
        cols={files.length < 3 ? files.length : 3}
        rowHeight={100}
        sx={{ p: 1 }}
      >
        {files.map((file) => (
          <ImageListItem
            key={file.name}
            sx={{
              border: "1px solid",
              borderColor: "grey.300",
              position: "relative",
            }}
          >
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              loading="lazy"
            />
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "error.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "error.dark",
                  color: "white",
                },
              }}
              onClick={() => {
                setFiles(files.filter((f) => f !== file));
                setValue &&
                  setValue(
                    id,
                    files.filter((f) => f !== file)
                  );
              }}
            >
              <Close />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
