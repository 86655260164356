/**
 * @module config/localStorage
 */

/**
 * @function getStorage
 * @description access value from localStorage
 * @param {string} key accessor key
 * @returns {string} value accessed from localStorate
 */
export function getStorage(key) {
  return localStorage.getItem("attendance_" + key);
}

/**
 * @function setStorage
 * @description Set value to localStorage
 * @param {string} key accessor key to retrieve data form localstorage
 * @param {string} value value to store in localstorage
 */
export function setStorage(key, value) {
  localStorage.setItem("attendance_" + key, value);
}
/**
 * @function removeStorage
 * @description Remove value from localStorage
 * @param {string} key accessor key
 */
export function removeStorage(key) {
  localStorage.removeItem("attendance_" + key);
}
