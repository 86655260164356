import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  readDevices,
  readOrganization,
  readOrganizationTypes,
} from "../../../api";
import { getOrganizationMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import { organizationSchema } from "../../../validations/schema";

function OrganizationFormElement({
  register,
  formState: { errors },
  setValue,
  editId,
  watch,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["fiscalYear", editId],
    queryFn: readOrganization(editId),
    enabled: !!editId,
  });

  const { data: orgTypeData, isLoading: orgTypeLoading } = useQuery({
    queryKey: ["orgTypes"],
    queryFn: readOrganizationTypes,
  });

  const { data: deviceData, isLoading: deviceLoading } = useQuery({
    queryKey: ["devices"],
    queryFn: readDevices("zkteco"),
  });

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={3}>
            <FormElement.Text
              label={t("name")}
              required
              register={register}
              id="name"
              error={errors?.["name"]?.message}
              defaultValue={data?.data?.data?.name}
            />
          </Grid2>
          <Grid2 sm={3}>
            <FormElement.Select
              label={t("organizationType")}
              required
              setValue={setValue}
              id="organizationType"
              value={watch("organizationType")}
              options={orgTypeData?.data?.data?.results?.map((item) => ({
                label: item?.name_np,
                value: item?.id,
              }))}
              isLoading={orgTypeLoading}
              defaultValue={data?.data?.data?.organizationType}
              error={errors?.["organizationType"]?.value?.message}
            />
          </Grid2>
          <Grid2 sm={3}>
            <FormElement.Text
              label={t("phone")}
              required
              type="tel"
              register={register}
              id="phone"
              error={errors?.["phone"]?.message}
              defaultValue={data?.data?.data?.phone}
            />
          </Grid2>
          <Grid2 sm={3}>
            <FormElement.Text
              label={t("address")}
              required
              type="address"
              register={register}
              id="address"
              error={errors?.["address"]?.message}
              defaultValue={data?.data?.data?.address}
            />
          </Grid2>
          <Grid2 sm={3}>
            <FormElement.Select
              label={t("devices")}
              required
              setValue={setValue}
              id="device"
              multiple
              value={watch("device")}
              isLoading={deviceLoading}
              options={deviceData?.data?.data?.results?.map((item) => ({
                label: item?.name,
                value: item?.serial_number,
              }))}
              error={errors?.["device"]?.message}
              defaultValue={data?.data?.data?.device}
            />
            <FormElement.File
              label={t("Logo")}
              setValue={setValue}
              id="image"
              error={errors?.["image"]?.message}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data) {
  let body = { ...data };
  if (data?.organizationType) {
    body.organizationType = data?.organizationType?.value;
  }
  if (data?.device?.length > 0) {
    body.device = data?.device?.map((item) => item?.value);
  }

  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    if (key === "image") {
      formData.append(key, body[key][0]);
    } else if (key == "device") {
      body[key].forEach((value, index) => {
        formData.append(`device[${index}]`, value);
      });
    } else {
      formData.append(key, body[key]);
    }
  });
  return { formData };
}

const OrganizationForm = withHookForm({
  title: "createOrganization",
  submitText: "createOrganization",
  validationSchema: organizationSchema(),
  mutationOptionFn: getOrganizationMutationOptions,
  transformData,
})(OrganizationFormElement);

const OrganizationUpdateForm = withHookForm({
  title: "updateOrganization",
  submitText: "updateOrganization",
  validationSchema: organizationSchema(),
  mutationOptionFn: getOrganizationMutationOptions,
  transformData,
})(OrganizationFormElement);

export { OrganizationUpdateForm };

export default OrganizationForm;
