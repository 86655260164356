import { ADToBS, BSToAD } from "@kumarad9/nepalicalendar";
import { Check, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getDashStats,
  readClasses,
  readDeptOfOrganization,
  readOrganizations,
} from "../../api";
import { getMonthlyReportSheetQueryOptions } from "../../api/queryOptions";
import Export from "../../components/Buttons/Export";
import FormElement from "../../components/form";
import { ROLES } from "../../constants";
import { getDaysOfCurrentMonth, MONTH_NAMES } from "../../constants/calendar";
import useApp from "../../hooks/useApp";
import List from "../../layouts/List";
import nepaliNumberConverter from "../../utils/convertToNepaliNumber";
import queryGenerator from "../../utils/queryGenerator";
export default function MonthlyReportSheet() {
  const { t } = useTranslation();
  const app = useApp();

  const defaultFilter = {
    role: null,
    department: null,
    grade: null,
    section: null,
    year: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).split("/")[0],
    month: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).split("/")[1],
    organization: null,
  };

  const [filters, setFilters] = useState(defaultFilter);

  const { data: statsData } = useQuery({
    queryKey: ["stats"],
    queryFn: getDashStats,
    staleTime: Infinity,
  });

  const { data: orgData, isLoading: isOrgLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
    enabled: app?.user?.role === "admin",
  });

  const { data: deptData, isLoading: isDeptLoading } = useQuery({
    queryKey: ["departments", filters.organization],
    queryFn: readDeptOfOrganization(filters.organization),
    enabled: !!filters.organization,
  });

  const { data: classData, isLoading: isClassLoading } = useQuery({
    queryKey: ["classes", filters.organization],
    queryFn: readClasses(
      queryGenerator({ organization: filters.organization })
    ),
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (attendee) => attendee?.roll_no || attendee?.userId,
        header: t("ID"),
        style: {
          width: "50px",
        },
      },
      {
        accessorFn: (attendee) =>
          `${attendee?.first_name} ${attendee?.last_name}`,
        header: t("name"),
        style: {
          width: "200px",
        },
      },
      ...Array.from({
        length: getDaysOfCurrentMonth(filters.year, filters.month),
      }).map((_, i) => {
        return {
          accessorKey: `days.${i}`,
          cell: ({ row }) => {
            return (
              <Typography color={row.original.days?.[i]?.color}>
                {row.original.days?.[i]?.value}
              </Typography>
            );
          },
          header: nepaliNumberConverter(`${i + 1}`),
          meta: {
            noFilter: true,
          },
        };
      }),
    ],
    [t, filters.year, filters.month]
  );

  const FiltersComponents = [
    ...(app?.user?.role === "admin"
      ? [
          <FormElement.Select
            label={t("organization")}
            key={"organization"}
            options={orgData?.data?.data?.results?.map((org) => ({
              value: org?.id,
              label: org?.name,
            }))}
            isLoading={isOrgLoading}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, organization: value?.value }));
            }}
          />,
        ]
      : []),
    <FormElement.Select
      label={t("class")}
      key={"class"}
      options={classData?.data?.data?.results?.map((clas) => ({
        value: clas?.id,
        label: clas?.name,
      }))}
      isLoading={isClassLoading}
      disabled={app?.user?.role === "admin" ? !filters.organization : false}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, grade: value?.value }));
      }}
    />,
    <FormElement.Select
      label={t("department")}
      key={"department"}
      options={deptData?.data?.data?.results?.map((dept) => ({
        value: dept?.id,
        label: dept?.name,
      }))}
      disabled={app?.user?.role === "admin" ? !filters.organization : false}
      isLoading={isDeptLoading}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, department: value?.value }));
      }}
    />,
    <FormElement.Select
      label={t("role")}
      key={"role"}
      options={ROLES.map((role) => ({
        value: role,
        label: t(role),
      }))}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, role: value?.value }));
      }}
    />,
    ...(filters?.role === "student"
      ? [
          <FormElement.Select
            label="Grade"
            key={"grade"}
            options={statsData?.data?.data?.grades?.map((grade) => ({
              value: grade,
              label: grade,
            }))}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, grade: value?.value }));
            }}
          />,
          <FormElement.Select
            label="Section"
            key={"section"}
            options={statsData?.data?.data?.sections?.map((section) => ({
              value: section,
              label: section,
            }))}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, section: value?.value }));
            }}
          />,
        ]
      : []),
    <FormElement.Select
      label="Year"
      key={"year"}
      options={[
        { value: "2080", label: "2080" },
        { value: "2081", label: "2081" },
        { value: "2082", label: "2082" },
        { value: "2083", label: "2083" },
        { value: "2084", label: "2084" },
        { value: "2085", label: "2085" },
        { value: "2086", label: "2086" },
        { value: "2087", label: "2087" },
        { value: "2088", label: "2088" },
        { value: "2089", label: "2089" },
        { value: "2090", label: "2090" },
        { value: "2091", label: "2091" },
        { value: "2092", label: "2092" },
        { value: "2093", label: "2093" },
        { value: "2094", label: "2094" },
        { value: "2095", label: "2095" },
        { value: "2096", label: "2096" },
        { value: "2097", label: "2097" },
        { value: "2098", label: "2098" },
        { value: "2099", label: "2099" },
      ]}
      defaultValue={
        ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).split("/")[0]
      }
      onChange={(value) => {
        setFilters((prev) => ({
          ...prev,
          year: value?.value,
        }));
      }}
    />,
    <FormElement.Select
      label="Month"
      key={"month"}
      options={Object.entries(MONTH_NAMES).map(([key, value]) => ({
        value: key,
        label: value,
      }))}
      defaultValue={
        ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).split("/")[1]
      }
      onChange={(value) => {
        setFilters((prev) => ({
          ...prev,
          month: value?.value,
        }));
      }}
    />,
  ];

  let query = useMemo(() => {
    return Object.entries(filters)
      .filter(([_, value]) => value !== null)
      .reduce((acc, [key, value]) => {
        return acc === "?" ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
      }, "?");
  }, [filters]);

  return (
    <Box p={2}>
      <List
        filters={FiltersComponents}
        clearFilter={() => setFilters(defaultFilter)}
        tableSize="small"
        title="monthlyReportSheet"
        columns={columns}
        otherButtons={[
          <Export url={`/report/attendance/excel/${query || ""}`} />,
        ]}
        queryOptionFn={getMonthlyReportSheetQueryOptions}
        queryState={{
          query,
        }}
        transformData={(data) => {
          return data?.data?.data?.results?.map((item) => ({
            ...item,
            days: Array.from({
              length: getDaysOfCurrentMonth(filters.year, filters.month),
            }).map((_, i) => {
              if (
                dayjs(new Date()).diff(
                  BSToAD(filters.year + "/" + filters.month + "/" + (i + 1))
                ) < 0
              )
                return { value: "-", present: null };
              if (item?.days?.[("0" + (i + 1)).slice(-2)])
                return {
                  value: <Check fontSize="12" />,
                  present: true,
                  color: "success.main",
                };
              else
                return {
                  value: <Close fontSize="12" />,
                  present: false,
                  color: "error.main",
                };
            }),
          }));
        }}
      />
    </Box>
  );
}
