import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonTab from "../../../../../components/ButtonTab";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import pick from "../../../../../utils/pick";
import EmployeeList from "./EmployeeList";
import StudentList from "./StudentList";
import TeacherList from "./TeacherList";
export default function Attendees() {
  const { t } = useTranslation();
  const roles = useUrlQuery("roles");
  const ATTENDEETYPES = {
    student: t("student"),
    teacher: t("teacher"),
    employee: t("employee"),
  };
  const [attendeeType, setAttendeeType] = useState(
    roles?.split(",")[0] || "student"
  ); // student | teacher | employee
  const attendeeComponent = {
    student: <StudentList />,
    teacher: <TeacherList />,
    employee: <EmployeeList />,
  };
  return (
    <Box p={2}>
      {roles?.split(",").length > 1 && (
        <ButtonTab
          state={attendeeType}
          setState={setAttendeeType}
          buttons={pick(ATTENDEETYPES, roles?.split(","))}
        />
      )}
      {attendeeComponent[attendeeType]}
    </Box>
  );
}
