import React from "react";

import { Loop } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Input,
  MenuItem,
  Table as MuiTable,
  Paper,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import convertToNepaliNumber from "../utils/convertToNepaliNumber";

export default function Table({
  data = [],
  columns = [],
  isLoading,
  size,
  manualPagination = false,
  manualPaginationState = {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    setPagination: () => {},
    rowCount: 1,
  },
}) {
  const { t } = useTranslation();

  const [columnFilters, setColumnFilters] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    ...(manualPagination
      ? {
          rowCount: manualPaginationState.rowCount,
        }
      : {
          getPaginationRowModel: getPaginationRowModel(),
        }),
    state: {
      columnFilters,
      ...(manualPagination
        ? {
            pagination: manualPaginationState.pagination,
            onPaginationChange: manualPaginationState.setPagination,
          }
        : {}),
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    manualPagination,
  });

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <TablePaginationComponent
        table={table}
        isLoading={isLoading}
        manualPagination={manualPagination}
        manualPaginationState={manualPaginationState}
      />
      <MuiTable size={size}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableCell key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <Box
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          sx={header.column.columnDef?.style || {}}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </Box>
                        {!header.column.columnDef?.meta?.noFilter &&
                        header.column.getCanFilter() ? (
                          <Box>
                            <Filter
                              column={header.column}
                              isLoading={isLoading}
                            />
                          </Box>
                        ) : null}
                      </>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center", py: 8 }}
                colSpan={table.getAllColumns().length}
              >
                <Loop
                  className="tw-animate-spin"
                  fontSize="large"
                  color="primary"
                />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && table.getRowModel().rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ py: 8 }} colSpan={table.getAllColumns().length}>
                <Stack alignItems="center" rowGap={2}>
                  <img
                    srcSet={`/assets/nodata.svg?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`/assets/nodata.svg?w=164&h=164&fit=crop&auto=format`}
                    alt={t("noData")}
                    width={150}
                    loading="lazy"
                  />
                  <Typography color="grey.500" variant="h6">
                    {t("noData")}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
      <TablePaginationComponent
        table={table}
        isLoading={isLoading}
        manualPagination={manualPagination}
        manualPaginationState={manualPaginationState}
      />
    </TableContainer>
  );
}

function Filter({ column, isLoading }) {
  const { t, i18n } = useTranslation();
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  const sortedUniqueValues = React.useMemo(
    () =>
      filterVariant === "range"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .sort()
            .slice(0, 5000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues(), filterVariant]
  );

  return filterVariant === "range" ? (
    <Box>
      <Stack direction={"row"} gap={1}>
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={columnFilterValue?.[0] ?? ""}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          onChange={(value) =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`${t("min")} ${
            column.getFacetedMinMaxValues()?.[0] !== undefined
              ? `(${
                  i18n.language === "np"
                    ? convertToNepaliNumber(
                        column.getFacetedMinMaxValues()?.[0]
                      )
                    : column.getFacetedMinMaxValues()?.[0]
                })`
              : ""
          }`}
          disabled={isLoading}
          fullWidth={false}
        />
        <DebouncedInput
          type="number"
          value={columnFilterValue?.[1] ?? ""}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          onChange={(value) =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`${t("max")} ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${
                  i18n.language === "np"
                    ? convertToNepaliNumber(
                        column.getFacetedMinMaxValues()?.[1]
                      )
                    : column.getFacetedMinMaxValues()?.[1]
                })`
              : ""
          }`}
          disabled={isLoading}
          fullWidth={false}
        />
      </Stack>
      <div className="h-1" />
    </Box>
  ) : filterVariant === "select" ? (
    <FormControl variant="standard">
      <Select
        size="small"
        onChange={(e) => column.setFilterValue(e.target.value)}
        value={columnFilterValue ? columnFilterValue?.toString() : ""}
        disabled={isLoading}
      >
        <option value="">All</option>
        {sortedUniqueValues.map((value, i) => (
          //dynamically generated select options from faceted values feature
          <MenuItem value={value} key={i}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <>
      {/* Autocomplete suggestions from faceted values feature */}
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${t("search")}... (${
          i18n.language === "np"
            ? convertToNepaliNumber(column.getFacetedUniqueValues().size)
            : column.getFacetedUniqueValues().size
        })`}
        list={column.id + "list"}
        disabled={isLoading}
      />
    </>
  );
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  fullWidth = true,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      fullWidth={fullWidth}
      sx={{
        fontSize: "12px",
        flexGrow: 1,
      }}
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function TablePaginationComponent({
  table,
  isLoading,
  manualPagination = false,
  manualPaginationState,
}) {
  const { t, i18n } = useTranslation();
  function paginationDisplayedRowsLabel({ from, to, count }) {
    if (isLoading) return <Loop fontSize="small" className="tw-animate-spin" />;
    if (i18n.language === "np") {
      return ` ${
        count !== -1 ? convertToNepaliNumber(`${count}`) : `more than ${to}`
      } डाटाको ${convertToNepaliNumber(`${from}`)} देखि ${convertToNepaliNumber(
        `${to}`
      )} सम्म`;
    } else {
      return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    }
  }
  return (
    <TablePagination
      component={"div"}
      labelRowsPerPage={t("rowsPerPage")}
      rowsPerPageOptions={[
        { label: t("10"), value: 10 },
        { label: t("25"), value: 25 },
        { label: t("50"), value: 50 },
        { label: t("100"), value: 100 },
        { label: t("250"), value: 250 },
        { label: t("500"), value: 500 },
        { label: t("1000"), value: 1000 },
      ]}
      labelDisplayedRows={paginationDisplayedRowsLabel}
      count={table.getRowCount()}
      page={table.getState().pagination.pageIndex}
      disabled={isLoading}
      onPageChange={(_, newPage) => {
        if (manualPagination) {
          manualPaginationState.setPagination((prev) => ({
            ...prev,
            pageIndex: newPage,
          }));
        } else {
          table.setPageIndex(newPage);
        }
      }}
      rowsPerPage={table.getState().pagination.pageSize}
      onRowsPerPageChange={(e) => table.setPageSize(e.target.value)}
    />
  );
}
