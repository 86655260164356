import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getSingleFiscalYear } from "../../../api";
import { getFiscalYearMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import { fiscalYearSchema } from "../../../validations/schema";

function FiscalYearFormElement({
  register,
  formState: { errors },
  setValue,
  editId,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["fiscalYear", editId],
    queryFn: getSingleFiscalYear(editId),
    enabled: !!editId,
  });

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("title")}
              register={register}
              required
              id="title"
              error={errors?.["title"]?.message}
              defaultValue={data?.data?.data?.title}
            />
          </Grid2>
          {/* <Grid2 sm={4}>
            <NepaliCalendar as={TextField} label={"Date"} size="small" />
          </Grid2> */}
          <Grid2 sm={4}>
            <FormElement.Switch
              id="is_active"
              setValue={setValue}
              label={`${t("isActive")}?`}
              error={errors?.["is_active"]?.message}
              defaultChecked={data?.data?.data?.is_active}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

const FiscalYearForm = withHookForm({
  title: "createFiscalYear",
  submitText: "createFiscalYear",
  validationSchema: fiscalYearSchema(),
  mutationOptionFn: getFiscalYearMutationOptions,
})(FiscalYearFormElement);

const FiscalYearUpdateForm = withHookForm({
  title: "updateFiscalYear",
  submitText: "updateFiscalYear",
  validationSchema: fiscalYearSchema(),
  mutationOptionFn: getFiscalYearMutationOptions,
})(FiscalYearFormElement);

export { FiscalYearUpdateForm };

export default FiscalYearForm;
