import {
  readAbsentList,
  readActivityLogs,
  readAttendee,
  readClasses,
  readDeptOfOrganization,
  readDevices,
  readFiscalYears,
  readHolidays,
  readIndividualReport,
  readMonthlyReportSheet,
  readOrganizations,
  readOrganizationTypes,
  readShifts,
  readUsers,
} from ".";
import pick from "../utils/pick";
import queryGenerator from "../utils/queryGenerator";

export function getFiscalYearQueryOptions() {
  return {
    queryKey: ["fiscalYear"],
    queryFn: readFiscalYears,
  };
}

export function getShiftQueryOptions({ queryState }) {
  const queryObj = pick(queryState, ["organization"]);
  return {
    queryKey: ["shifts"],
    queryFn: readShifts(queryGenerator(queryObj)),
  };
}

export function getOrgTypeQueryOptions() {
  return {
    queryKey: ["organizationTypes"],
    queryFn: readOrganizationTypes,
  };
}

export function getOrganizationQueryOptions() {
  return {
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  };
}

export function getDepartmentQueryOptions({ queryState }) {
  return {
    queryKey: ["departments"],
    queryFn: readDeptOfOrganization(queryState?.organization),
    enabled: !!queryState?.organization,
  };
}

export function getClassQueryOptions({ queryState }) {
  const queryObj = pick(queryState, ["organization"]);
  return {
    queryKey: ["classes"],
    queryFn: readClasses(queryGenerator(queryObj)),
  };
}

export function getHolidayQueryOptions({ queryState }) {
  const queryObj = pick(queryState, []);
  return {
    queryKey: ["holidays"],
    queryFn: readHolidays(queryGenerator(queryObj)),
  };
}

export function getUserQueryOptions({ queryState }) {
  const query = pick(queryState, [
    "role",
    "limit",
    "page",
    "noPagination",
    "organization",
    "department",
    "grade",
  ]);
  query.page = query.pageIndex;
  delete query.pageIndex;
  query.limit = query.pageSize;
  delete query.pageSize;
  return {
    queryKey: ["users", ...Object.values(queryState)],
    queryFn: readUsers(queryGenerator(query)),
  };
}

export function getAttendeeQueryOptions({ queryState }) {
  const filter = pick(queryState, ["class", "department"]);
  const query = Object.entries(filter).reduce((acc, [key, value]) => {
    if (value) {
      return acc === "?" ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
    }
    return acc;
  }, "?");
  return {
    queryKey: ["attendees", queryState?.role, query],
    queryFn: readAttendee(queryState?.role, query),
  };
}

export function getDevicesQueryOptions({ queryState }) {
  return {
    queryKey: ["devices", queryState?.type],
    queryFn: readDevices(queryState?.type),
  };
}

export function getActivityLogQueryOptions({ queryState }) {
  const query = pick(queryState, [
    "grade",
    "page",
    "limit",
    "noPagination",
    "from",
    "to",
  ]);
  return {
    queryKey: ["activity-log", ...Object.values(queryState)],
    queryFn: readActivityLogs(queryGenerator(query)),
    refetchInterval: 15000,
  };
}

export function getMonthlyReportSheetQueryOptions({ queryState }) {
  return {
    queryKey: ["monthly-report-sheet", queryState?.query],
    queryFn: readMonthlyReportSheet(queryState?.query),
  };
}

export function getIndividualReportQueryOptions({ queryState }) {
  return {
    queryKey: ["individual-report", queryState?.query],
    queryFn: readIndividualReport(queryState?.id, queryState?.query),
    enabled: queryState.enabled,
  };
}

export function getAbsentListQueryOptions({ queryState }) {
  return {
    queryKey: ["absent-list", queryState?.query],
    queryFn: readAbsentList(queryState?.query),
    enabled: queryState.enabled,
  };
}
