import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getOrganizationMutationOptions } from "../../../api/mutationOptions";
import { getOrganizationQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import List from "../../../layouts/List";
export default function Organizations() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorFn: (row) =>
          i18n.language === "np"
            ? row.organizationType?.name_np
            : row.organizationType?.name_en,
        header: t("orgType"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
      {
        accessorKey: "address",
        header: t("address"),
      },

      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/organization/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getOrganizationMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
              view: {
                onClick: () =>
                  navigate(
                    `/management/organization/${row.original.id}/departments/1`
                  ),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, i18n]
  );

  return (
    <Box p={2}>
      <List
        title="organizations"
        columns={columns}
        create={{
          title: "createOrganization",
          path: "/management/organization/create",
        }}
        queryOptionFn={getOrganizationQueryOptions}
      />
    </Box>
  );
}
