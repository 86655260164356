import { toast } from "react-toastify";
import {
  createFiscalYear,
  mutateAttendee,
  mutateClass,
  mutateDevice,
  mutateHoliday,
  mutateOrganization,
  mutateOrganizationType,
  mutateShift,
  mutateUsers,
  syncDevice,
} from ".";
import { queryClient } from "../App";

export function getFiscalYearMutationOptions({ navigate }) {
  return {
    mutationKey: ["fiscalYear"],
    mutationFn: createFiscalYear,
    onSuccess: () => {
      queryClient.refetchQueries(["fiscalYears"]);
      navigate("/management/fiscalYears/1");
    },
  };
}
export function getShiftMutationOptions({ navigate }) {
  return {
    mutationKey: ["shift"],
    mutationFn: mutateShift,
    onSuccess: () => {
      queryClient.refetchQueries(["shifts"]);
      navigate("/management/shifts/1");
    },
  };
}

export function getOrgTypeMutationOptions({ navigate }) {
  return {
    mutationKey: ["organizationType"],
    mutationFn: mutateOrganizationType,
    onSuccess: () => {
      queryClient.refetchQueries(["organizationTypes"]);
      navigate("/management/organization-types/1");
    },
  };
}

export function getOrganizationMutationOptions({ navigate }) {
  return {
    mutationKey: ["organization"],
    mutationFn: mutateOrganization,
    onSuccess: () => {
      queryClient.refetchQueries(["organizations"]);
      navigate("/management/organizations/1");
    },
  };
}

export function getDepartmentMutationOptions({ navigate, params }) {
  return {
    mutationKey: ["department"],
    mutationFn: mutateOrganization,
    onSuccess: () => {
      queryClient.refetchQueries(["departments"]);
      navigate(`/management/organization/${params?.orgId}/departments/1`);
    },
  };
}

export function getUserMutationOptions({ navigate, mutationState }) {
  return {
    mutationKey: ["user"],
    mutationFn: mutateUsers,
    onSuccess: () => {
      queryClient.refetchQueries(["users"]);
      navigate(mutationState?.navigateTo || "/management/users/1");
    },
  };
}

export function getAttendeeMutationOptions({ navigate }) {
  return {
    mutationKey: ["attendee"],
    mutationFn: mutateAttendee,
    onSuccess: () => {
      queryClient.refetchQueries(["attendees"]);
      navigate(`/attendees/all`);
    },
  };
}

export function getDeviceMutationOptions({ navigate }) {
  return {
    mutationKey: ["device"],
    mutationFn: mutateDevice,
    onSuccess: () => {
      queryClient.refetchQueries(["devices"]);
      navigate(`/management/devices/1`);
    },
  };
}

export function getDeviceSyncMutationOptions() {
  return {
    mutationKey: ["device-sync"],
    mutationFn: syncDevice,
    onSuccess: () => {
      toast.success("Device synced successfully");
    },
  };
}

export function getClassMutationOptions({ navigate }) {
  return {
    mutationKey: ["class"],
    mutationFn: mutateClass,
    onSuccess: () => {
      queryClient.refetchQueries(["classes"]);
      navigate("/management/classes/1");
    },
  };
}

export function getHolidayMutationOptions({ navigate }) {
  return {
    mutationKey: ["holiday"],
    mutationFn: mutateHoliday,
    onSuccess: () => {
      queryClient.refetchQueries(["holidays"]);
      navigate("/management/holidays/1");
    },
  };
}
