import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getOrgTypeMutationOptions } from "../../../api/mutationOptions";
import { getOrgTypeQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import List from "../../../layouts/List";
export default function OrganizationType() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: t("name"),
        accessorFn: (row) =>
          `${i18n.language === "np" ? row.name_np : row.name_en}`,
      },
      {
        header: t("attendeeTypes"),
        cell: ({ row }) =>
          row.original.attendeeTypes?.length > 0 ? (
            <ul>
              {row.original.attendeeTypes.map((attendeeType, i) => (
                <li key={i}>{attendeeType}</li>
              ))}
            </ul>
          ) : (
            ""
          ),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(
                    `/management/organization-type/edit/${row.original.id}`
                  ),
              },
              delete: {
                mutationOptionFn: getOrgTypeMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, i18n.language]
  );

  return (
    <Box p={2}>
      <List
        title="orgTypes"
        columns={columns}
        create={{
          title: "createOrgType",
          path: "/management/organization-type/create",
        }}
        queryOptionFn={getOrgTypeQueryOptions}
      />
    </Box>
  );
}
