/**
 * @module config/permissions
 */
import { getStorage } from "./localStorage";

/**
 * @description A list of permissions for each role.
 * @name permissions
 * @type {Object}
 */
export const permissions = {
  admin: ["*", "!view:allDepartments"],
  organizationAdmin: [
    "view:allAttendee",
    "verify:attendee",
    "view:allDepartments",
    "view:classes",
    "view:shifts",
    "view:devices",
    "view:holidays",
  ],
};

/**
 *
 * @param {string} role
 * @returns {string[]}
 */
function getPermissions(role) {
  return permissions[role] || [];
}

/**
 *
 * @param {string[] | string} requiredPermission - The required permissions.
 * @param {string} [role] - The role.
 * @returns {boolean} - Whether the user has the required permissions.
 */
export function hasPermissions(
  allowedPermissions = [],
  role = getStorage("role") || ""
) {
  const aps = [].concat(allowedPermissions).flat();
  let canAccess = false;
  if (aps.length === 0) return true;

  for (let i = 0; i < aps.length; i++) {
    canAccess = getPermissions(role)?.includes(aps[i]);
    if (getPermissions(role)?.includes("!" + aps[i])) return false;
    if (permissions[role]?.includes("*")) {
      return true;
    }
    if (!canAccess) break;
  }

  return canAccess;
}
