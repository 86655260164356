export const calendar_data = {
  1970: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1971: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365],
  1972: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  1973: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  1974: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1975: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  1976: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  1977: [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
  1978: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1979: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  1980: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  1981: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  1982: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1983: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  1984: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  1985: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  1986: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1987: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  1988: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  1989: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1990: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1991: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  1992: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  1993: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1994: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1995: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  1996: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  1997: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1998: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  1999: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2000: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  2001: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2002: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2003: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2004: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  2005: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2006: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2007: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2008: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
  2009: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2010: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2011: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2012: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2013: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2014: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2015: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2016: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2017: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2018: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2019: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  2020: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2021: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2022: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  2023: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  2024: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2025: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2026: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2027: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  2028: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2029: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365],
  2030: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2031: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  2032: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2033: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2034: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2035: [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
  2036: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2037: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2038: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2039: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2040: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2041: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2042: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2043: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2044: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2045: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2046: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2047: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2048: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2049: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  2050: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  2051: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2052: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2053: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  2054: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  2055: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2056: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365],
  2057: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2058: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
  2059: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2060: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2061: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2062: [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31, 365],
  2063: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2064: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2065: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2066: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
  2067: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2068: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2069: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2070: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2071: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2072: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2073: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2074: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2075: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2076: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  2077: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  2078: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2079: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2080: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  2081: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366],
  2082: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2083: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2084: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2085: [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30, 366],
  2086: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2087: [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
  2088: [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30, 365],
  2089: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2090: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  2091: [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
  2092: [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366],
  2093: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
  2094: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2095: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  2096: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  2097: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  2098: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  2099: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
};
export const FIRST_DAY_OF_MONTH = {
  1970: [0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
  1971: [1, 4, 0, 4, 0, 4, 6, 1, 2, 4, 5, 0],
  1972: [2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
  1973: [4, 6, 3, 6, 3, 6, 1, 3, 5, 6, 1, 2],
  1974: [5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
  1975: [6, 2, 5, 2, 6, 2, 4, 6, 0, 2, 3, 5],
  1976: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
  1977: [2, 4, 1, 4, 1, 4, 0, 1, 3, 5, 6, 0],
  1978: [3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
  1979: [4, 0, 3, 0, 4, 0, 2, 4, 5, 0, 1, 3],
  1980: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
  1981: [0, 3, 6, 2, 6, 2, 5, 6, 1, 3, 4, 6],
  1982: [1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
  1983: [2, 5, 1, 5, 2, 5, 0, 2, 3, 5, 6, 1],
  1984: [3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
  1985: [5, 1, 4, 0, 4, 0, 3, 4, 6, 1, 2, 4],
  1986: [6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
  1987: [0, 3, 0, 3, 0, 3, 5, 0, 1, 3, 4, 6],
  1988: [1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
  1989: [3, 6, 2, 5, 2, 5, 1, 3, 4, 6, 0, 2],
  1990: [4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
  1991: [5, 1, 5, 1, 5, 1, 3, 5, 6, 1, 2, 4],
  1992: [6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 4, 5],
  1993: [1, 4, 0, 3, 0, 3, 6, 1, 2, 4, 5, 0],
  1994: [2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
  1995: [3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
  1996: [4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 2, 3],
  1997: [6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
  1998: [0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
  1999: [1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
  2000: [3, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 1],
  2001: [4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
  2002: [5, 1, 4, 1, 5, 1, 3, 5, 6, 1, 2, 4],
  2003: [6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
  2004: [1, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 6],
  2005: [2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
  2006: [3, 6, 2, 6, 3, 6, 1, 3, 4, 6, 0, 2],
  2007: [4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
  2008: [6, 2, 5, 1, 5, 1, 4, 5, 0, 2, 3, 4],
  2009: [0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
  2010: [1, 4, 0, 4, 1, 4, 6, 1, 2, 4, 5, 0],
  2011: [2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
  2012: [4, 0, 3, 6, 3, 6, 2, 3, 5, 0, 1, 3],
  2013: [5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
  2014: [6, 2, 5, 2, 6, 2, 4, 6, 0, 2, 3, 5],
  2015: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
  2016: [2, 5, 1, 4, 1, 4, 0, 1, 3, 5, 6, 1],
  2017: [3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
  2018: [4, 0, 4, 0, 4, 0, 2, 4, 5, 0, 1, 3],
  2019: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 4],
  2020: [0, 3, 6, 2, 6, 2, 5, 0, 1, 3, 4, 6],
  2021: [1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
  2022: [2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
  2023: [3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 1, 2],
  2024: [5, 1, 4, 0, 4, 0, 3, 5, 6, 1, 2, 4],
  2025: [6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
  2026: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
  2027: [2, 4, 1, 4, 1, 4, 6, 1, 3, 4, 6, 0],
  2028: [3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
  2029: [4, 0, 3, 0, 3, 0, 2, 4, 5, 0, 1, 3],
  2030: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
  2031: [0, 2, 6, 2, 6, 2, 4, 6, 1, 2, 4, 5],
  2032: [1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
  2033: [2, 5, 1, 5, 2, 5, 0, 2, 3, 5, 6, 1],
  2034: [3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
  2035: [5, 0, 4, 0, 4, 0, 3, 4, 6, 1, 2, 3],
  2036: [6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
  2037: [0, 3, 6, 3, 0, 3, 5, 0, 1, 3, 4, 6],
  2038: [1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
  2039: [3, 6, 2, 5, 2, 5, 1, 2, 4, 6, 0, 2],
  2040: [4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
  2041: [5, 1, 4, 1, 5, 1, 3, 5, 6, 1, 2, 4],
  2042: [6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
  2043: [1, 4, 0, 3, 0, 3, 6, 0, 2, 4, 5, 0],
  2044: [2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
  2045: [3, 6, 3, 6, 3, 6, 1, 3, 4, 6, 0, 2],
  2046: [4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
  2047: [6, 2, 5, 1, 5, 1, 4, 6, 0, 2, 3, 5],
  2048: [0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
  2049: [1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
  2050: [2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 1],
  2051: [4, 0, 3, 6, 3, 6, 2, 4, 5, 0, 1, 3],
  2052: [5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
  2053: [6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
  2054: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 6],
  2055: [2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
  2056: [3, 6, 2, 6, 2, 6, 1, 3, 4, 6, 0, 2],
  2057: [4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
  2058: [6, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 4],
  2059: [0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
  2060: [1, 4, 0, 4, 1, 4, 6, 1, 2, 4, 5, 0],
  2061: [2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
  2062: [4, 6, 3, 6, 3, 6, 2, 3, 5, 6, 1, 2],
  2063: [5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
  2064: [6, 2, 5, 2, 6, 2, 4, 6, 0, 2, 3, 5],
  2065: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
  2066: [2, 5, 1, 4, 1, 4, 0, 1, 3, 5, 6, 0],
  2067: [3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
  2068: [4, 0, 3, 0, 4, 0, 2, 4, 5, 0, 1, 3],
  2069: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
  2070: [0, 3, 6, 2, 6, 2, 5, 6, 1, 3, 4, 6],
  2071: [1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
  2072: [2, 5, 2, 5, 2, 5, 0, 2, 3, 5, 6, 1],
  2073: [3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
  2074: [5, 1, 4, 0, 4, 0, 3, 5, 6, 1, 2, 4],
  2075: [6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
  2076: [0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
  2077: [1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 6, 0],
  2078: [3, 6, 2, 5, 2, 5, 1, 3, 4, 6, 0, 2],
  2079: [4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
  2080: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
  2081: [6, 2, 5, 2, 6, 2, 4, 6, 1, 2, 4, 6],
  2082: [1, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 0],
  2083: [2, 5, 1, 5, 1, 4, 6, 1, 3, 4, 6, 1],
  2084: [3, 6, 2, 6, 2, 5, 0, 2, 4, 5, 0, 2],
  2085: [4, 0, 4, 0, 4, 6, 2, 4, 6, 0, 2, 4],
  2086: [6, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 5],
  2087: [0, 3, 6, 3, 6, 2, 5, 0, 2, 3, 5, 0],
  2088: [2, 4, 0, 4, 1, 3, 6, 1, 3, 4, 6, 1],
  2089: [3, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 2],
  2090: [4, 6, 3, 6, 3, 6, 1, 3, 5, 6, 1, 3],
  2091: [5, 1, 4, 1, 4, 0, 3, 5, 0, 1, 3, 5],
  2092: [0, 3, 6, 3, 0, 3, 5, 0, 2, 3, 5, 0],
  2093: [2, 5, 1, 4, 1, 4, 0, 1, 3, 5, 6, 0],
  2094: [3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
  2095: [4, 0, 3, 0, 4, 0, 2, 4, 5, 0, 1, 3],
  2096: [5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
  2097: [0, 3, 6, 2, 6, 2, 5, 6, 1, 3, 4, 6],
  2098: [1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
  2099: [2, 5, 1, 5, 2, 5, 0, 2, 3, 5, 6, 1],
};

export const MONTH_NAMES = {
  "01": "बैशाख",
  "02": "जेठ",
  "03": "असार",
  "04": "श्रावण",
  "05": "भाद्र",
  "06": "आश्विन",
  "07": "कार्तिक",
  "08": "मङसिर",
  "09": "पौष",
  10: "माघ",
  11: "फाल्गुन",
  12: "चैत्र",
};

export function getDaysOfCurrentMonth(year = null, month = null) {
  if (year && month) {
    return calendar_data[year][month - 1];
  }
  return 0;
}
