import { ADToBS } from "@kumarad9/nepalicalendar";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { readClasses, readOrganizations } from "../../api";
import { getActivityLogQueryOptions } from "../../api/queryOptions";
import FormElement from "../../components/form";
import useApp from "../../hooks/useApp";
import List from "../../layouts/List";
import queryGenerator from "../../utils/queryGenerator";

export default function ActivityLog() {
  const { t } = useTranslation();

  const app = useApp();

  const defaultFilter = {
    organization: null,
    grade: null,
    from: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
    to: ADToBS(dayjs(new Date()).format("YYYY-MM-DD")).replace(/\//g, "-"),
  };

  const [filters, setFilters] = useState(defaultFilter);

  const { data: classData, isLoading: isClassLoading } = useQuery({
    queryKey: ["class", filters.organization],
    queryFn: readClasses(
      queryGenerator({ organization: filters.organization })
    ),
  });

  const { data: orgData, isLoading: isOrgLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: `${t("attendee")}/ ${t("rollNo")}`,
        cell: ({ row }) => (
          <div>
            <Typography variant="body">
              {row.original.userInfo?.first_name +
                " " +
                row.original.userInfo?.last_name}
            </Typography>
            <Typography variant="caption" component={"p"}>
              {row.original.userInfo?.roll_no}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "userInfo.role",
        header: t("role"),
      },
      {
        accessorKey: "userInfo.grade.name",
        header: t("grade"),
      },
      {
        accessorKey: "userInfo.phone",
        header: t("phone"),
      },
      {
        accessorKey: "userInfo.shift.title",
        header: t("shift"),
      },
      {
        accessorKey: "time",
        header: t("time"),
        cell: ({ row }) => {
          return dayjs(row.original.time).format("YYYY/MM/DD hh:mm A");
        },
      },
    ],
    [t]
  );

  const FilterComponents = [
    <FormElement.Date
      key="from"
      id="from"
      label={t("dateFrom")}
      onDateChange={(value) => {
        if (value) {
          setFilters((prev) => ({ ...prev, from: value?.replace(/\//g, "-") }));
        }
      }}
    />,
    <FormElement.Date
      key="to"
      id="to"
      label={t("dateTo")}
      onDateChange={(value) =>
        setFilters((prev) => ({ ...prev, to: value?.replace(/\//g, "-") }))
      }
    />,
    app?.user?.role === "admin"
      ? [
          <FormElement.Select
            label={t("organization")}
            key={"organization"}
            options={orgData?.data?.data?.results?.map((org) => ({
              value: org?.id,
              label: org?.name,
            }))}
            isLoading={isOrgLoading}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, organization: value?.value }));
            }}
          />,
        ]
      : [],
    <FormElement.Select
      label={t("class")}
      key={"class"}
      options={classData?.data?.data?.results?.map((clas) => ({
        value: clas?.id,
        label: clas?.name,
      }))}
      isLoading={isClassLoading}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, grade: value?.value }));
      }}
      disabled={app?.user?.role === "admin" ? !filters.organization : false}
    />,
  ];

  return (
    <Box p={2}>
      <List
        manualPagination={true}
        filters={FilterComponents}
        clearFilter={() => setFilters(defaultFilter)}
        title="activityLog"
        columns={columns}
        queryOptionFn={getActivityLogQueryOptions}
        queryState={{
          ...filters,
          noPagination: false,
        }}
      />
    </Box>
  );
}
