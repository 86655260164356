import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getShiftMutationOptions } from "../../../api/mutationOptions";
import { getShiftQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import useApp from "../../../hooks/useApp";
import List from "../../../layouts/List";
import nepaliNumberConverter from "../../../utils/convertToNepaliNumber";
export default function Shifts() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const app = useApp();

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: t("title"),
      },
      {
        accessorKey: "start_time",
        header: t("startTime"),
        cell: ({ row }) => {
          return i18n.language === "np"
            ? nepaliNumberConverter(
                new Date(
                  "1970-01-01T" + row.original.start_time
                ).toLocaleTimeString()
              )
            : new Date(
                "1970-01-01T" + row.original.start_time
              ).toLocaleTimeString();
        },
      },
      {
        accessorKey: "end_time",
        header: t("endTime"),
        cell: ({ row }) => {
          return i18n.language === "np"
            ? nepaliNumberConverter(
                new Date(
                  "1970-01-01T" + row.original.end_time
                ).toLocaleTimeString()
              )
            : new Date(
                "1970-01-01T" + row.original.end_time
              ).toLocaleTimeString();
        },
      },
      ...(app?.user?.role === "admin"
        ? [
            {
              accessorKey: "organization.name",
              header: t("organization"),
            },
          ]
        : []),
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/shift/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getShiftMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, app?.user?.role, i18n.language]
  );

  return (
    <Box p={2}>
      <List
        title="shifts"
        columns={columns}
        create={{
          title: "createShift",
          path: "/management/shift/create",
        }}
        queryOptionFn={getShiftQueryOptions}
      />
    </Box>
  );
}
