import { Loop } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useReducer } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMe } from "../api";
import { getStorage, removeStorage } from "../config/localStorage";
const appInfo = require("../../package.json");

const initialState = {
  user: null,
  loading: false,
  version: appInfo?.version,
};

function appReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

/**
 *
 * @module context/appContext
 * @description App's state, provider and actions
 */

/**
 * @name AppContext
 * @typedef {object} AppContext
 * @property {any} user logged in user
 * @property {boolean} loading app is loading
 * @property {string} version app version
 * @property {function} setLoading function to set loading state of app | (loading: boolean) => void
 * @property {function} setUser function to set logged in user | (user: any) => void
 * @property {function} logout function to logout | () => void
 * @property {function} dispatch function to dispatch action | (action: any) => void
 *
 */
const AppContext = createContext(
  /** @type {AppContext} */ {
    ...initialState,
    setLoading: () => {},
    setUser: () => {},
    logout: () => {},
    dispatch: () => {},
  }
);

/**
 *
 * @function AppProvider
 * @memberof module:context/appContext
 * @param {object} props App Provider Component Props
 * @param {React.ReactNode} props.children App Provider Component Children
 *
 * @returns {React.ReactNode}
 */
export function AppProvider({ children }) {
  /**
   * State of App
   * @name state
   * @property {any} user logged in user
   * @property {boolean} loading app is loading
   * @property {string} version app version
   * @description state of app
   */
  const [state, dispatch] = useReducer(appReducer, initialState);

  /**
   * Function to change loading state of app
   * @function setLoading
   * @param {boolean} loading
   * @returns {void}
   */
  function setLoading(loading) {
    dispatch({ type: "SET_LOADING", payload: loading });
  }
  /**
   * Function to set logged in user in app's state
   * @function setUser
   * @param {any} user
   * @returns {void}
   */
  function setUser(user) {
    dispatch({ type: "SET_USER", payload: user });
  }

  /**
   * Function to logout user and clear app's user state
   * @function logout
   * @param {navigateFunction} navigate function to navigate
   * @returns {void}
   */
  function logout(navigate) {
    removeStorage("access");
    removeStorage("refresh");
    removeStorage("role");
    dispatch({ type: "SET_USER", payload: null });
    if (navigate) navigate("/auth/login");
  }

  const { data, error, isLoading } = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!getStorage("access"),
  });

  useEffect(() => {
    setLoading(true);
    if (!isLoading && error) {
      if (error?.response?.status === 401) {
        logout();
      } else {
        toast.error(error?.message);
      }
    } else if (!isLoading && data) {
      setUser(data?.data?.data);
    }
    setLoading(false);
  }, [data, isLoading, error]);

  return (
    <AppContext.Provider
      value={{ ...state, setLoading, setUser, logout, dispatch }}
    >
      {isLoading || state.loading ? (
        <Stack alignItems={"center"} justifyContent={"center"} height={"100vh"}>
          <Loop
            fontSize={"large"}
            color="primary"
            className="tw-animate-spin"
          />
        </Stack>
      ) : (
        children
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </AppContext.Provider>
  );
}

export default AppContext;
