import { useLocation } from "react-router-dom";

export default function useUrlQuery(key) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  if (!key) {
    let queries = {};
    for (const [key, value] of query.entries()) {
      queries[key] = value;
    }
    return queries;
  }
  if (Array.isArray(key)) {
    return key.map((k) => query.get(k) || "");
  } else {
    return query.get(key) || "";
  }
}
