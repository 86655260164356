import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { getStorage } from "../../config/localStorage";
import useApp from "../../hooks/useApp";
import convertToNepaliNumber from "../../utils/convertToNepaliNumber";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function DashboardLayout() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let year = new Date().getFullYear().toString();

  const app = useApp();

  useEffect(() => {
    if (!getStorage("access")) {
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <Stack
      bgcolor="background.default"
      direction={"row"}
      sx={{ height: "100vh" }}
    >
      <Sidebar />
      <Stack flex={1} overflow={"scroll"}>
        <Header />
        <Box flex={1}>
          <Outlet />
        </Box>
        <Stack
          p={1}
          direction={"row"}
          bgcolor={"primary.main"}
          color={"primary.contrastText"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <Typography variant="body2">
            &copy; {i18n.language === "np" ? convertToNepaliNumber(year) : year}
          </Typography>
          <Typography variant="body2">{t("poweredBy")}</Typography>
          <Typography variant="body2">
            {t("version")}:{" "}
            {i18n.language === "np"
              ? convertToNepaliNumber(app?.version)
              : app.version}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
