import { useContext } from "react";
import AppContext from "../contexts/AppContext";

/**
 * @module hooks/useApp
 */

/**
 * @function useApp
 * @description Hook to get app context
 *
 * @returns {object} AppContext
 * @property {any} user logged in user
 * @property {boolean} loading app is loading
 * @property {string} version app version
 * @property {function} setLoading function to set loading state of app | (loading: boolean) => void
 * @property {function} setUser function to set logged in user | (user: any) => void
 * @property {function} logout function to logout | () => void
 * @property {function} dispatch function to dispatch action | (action: any) => void
 *
 */
export default function useApp() {
  return useContext(AppContext);
}
