import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { readClass, readOrganizations } from "../../../api";
import { getClassMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import useApp from "../../../hooks/useApp";
import { classSchema } from "../../../validations/schema";

function ClassFormElement({
  register,
  formState: { errors },
  editId,
  setValue,
}) {
  const app = useApp();

  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["orgType", editId],
    queryFn: readClass(editId),
    enabled: !!editId,
  });
  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });
  useEffect(() => {
    if (data) {
      setValue("grade_text", data?.data?.data?.grade_text);
    }
  });
  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("name")}
              register={register}
              required
              id="name"
              error={errors?.["name"]?.message}
              defaultValue={data?.data?.data?.name}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Select
              label={t("grade")}
              setValue={setValue}
              required
              id="grade_text"
              options={[
                { label: "Nursery", value: "Nursery" },
                { label: "LKG", value: "LKG" },
                { label: "UKG", value: "UKG" },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" },
                { label: "11", value: "11" },
                { label: "12", value: "12" },
              ]}
              error={errors?.["grade_text"]?.message}
              defaultValue={data?.data?.data?.grade_text}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("section")}
              register={register}
              required
              id="section_text"
              error={errors?.["section_text"]?.message}
              defaultValue={data?.data?.data?.section_text}
            />
          </Grid2>
          {app?.user?.role === "admin" && (
            <Grid2 sm={4}>
              <FormElement.Select
                required
                id="organization"
                label={t("organization")}
                setValue={setValue}
                options={orgData?.data?.data?.results?.map((org) => ({
                  value: org?.id,
                  label: org?.name,
                }))}
                error={errors?.["organization"]?.message}
                defaultValue={data?.data?.data?.organization}
              />
            </Grid2>
          )}
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data) {
  let body = { ...data };
  if (data?.grade_text) {
    body.grade_text = data?.grade_text?.value;
  }
  if (data.organization) {
    body.organization = data?.organization?.value;
  }
  return body;
}

const ClassForm = withHookForm({
  title: "createClass",
  submitText: "createClass",
  validationSchema: classSchema(),
  mutationOptionFn: getClassMutationOptions,
  transformData,
})(ClassFormElement);

const ClassUpdateForm = withHookForm({
  title: "updateClass",
  submitText: "updateClass",
  validationSchema: classSchema(),
  mutationOptionFn: getClassMutationOptions,
  transformData,
})(ClassFormElement);

export { ClassUpdateForm };

export default ClassForm;
