import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readHoliday, readOrganizations } from "../../../api";
import { getHolidayMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import useApp from "../../../hooks/useApp";
import { holidaySchema } from "../../../validations/schema";

function HolidayFormElement({
  register,
  formState: { errors },
  setValue,
  editId,
  watch,
}) {
  const { t } = useTranslation();

  const app = useApp();

  const { data, isLoading } = useQuery({
    queryKey: ["holiday", editId],
    queryFn: readHoliday(editId),
    enabled: !!editId,
  });

  const { data: orgData, isLoading: isOrgLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          {app?.user?.role === "admin" && (
            <Grid2 sm={4}>
              <FormElement.Select
                label={t("organization")}
                setValue={setValue}
                required
                id="organization"
                options={orgData?.data?.data?.results?.map((org) => ({
                  label: org?.name,
                  value: org?.id,
                }))}
                error={errors?.["organization"]?.message}
                defaultValue={data?.data?.data?.organization}
                isLoading={isOrgLoading}
              />
            </Grid2>
          )}
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("name")}
              register={register}
              required
              id="name"
              error={errors?.["name"]?.message}
              defaultValue={data?.data?.data?.name}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Date
              label={t("dateFrom")}
              setValue={setValue}
              required
              id="startDate"
              error={errors?.["startDate"]?.message}
              defaultValue={data?.data?.data?.startDate}
            />
          </Grid2>
          <Grid2 sm={4}>
            {watch("isMoreThanOneDay") && (
              <FormElement.Date
                label={t("dateTo")}
                setValue={setValue}
                required
                id="endDate"
                error={errors?.["endDate"]?.message}
                defaultValue={data?.data?.data?.endDate}
              />
            )}
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Switch
              id="isMoreThanOneDay"
              setValue={setValue}
              label={t("isMoreThanOneDay")}
              error={errors?.["isMoreThanOneDay"]?.message}
              defaultChecked={data?.data?.data?.isMoreThanOneDay}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data, _, queries) {
  let body = { ...data };
  if (queries?.organization) {
    body.organization = queries.organization;
  } else {
    body.organization = data?.organization?.value;
  }
  body.startDate = body.startDate.replace(/\//g, "-");
  if (!data?.endDate) {
    body.endDate = data?.startDate;
  }
  body.endDate = body.endDate.replace(/\//g, "-");
  return body;
}

const HolidayForm = withHookForm({
  title: "createHoliday",
  submitText: "createHoliday",
  validationSchema: holidaySchema(),
  mutationOptionFn: getHolidayMutationOptions,
  transformData,
})(HolidayFormElement);

const HolidayUpdateForm = withHookForm({
  title: "updateHoliday",
  submitText: "updateHoliday",
  validationSchema: holidaySchema(),
  mutationOptionFn: getHolidayMutationOptions,
  transformData,
})(HolidayFormElement);

export { HolidayUpdateForm };

export default HolidayForm;
