import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readOrganizationType } from "../../../api";
import { getOrgTypeMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import { ROLES } from "../../../constants";
import withHookForm from "../../../hocs/withHookForm";
import { organizationTypeSchema } from "../../../validations/schema";

function OrganizationFormElement({
  register,
  formState: { errors },
  editId,
  setValue,
  watch,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["orgType", editId],
    queryFn: readOrganizationType(editId),
    enabled: !!editId,
  });
  console.log(errors);
  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("nameNp")}
              register={register}
              required
              id="name_np"
              error={errors?.["name_np"]?.message}
              defaultValue={data?.data?.data?.name_np}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              register={register}
              required
              id="name_en"
              label={t("nameEn")}
              error={errors?.["name_en"]?.message}
              defaultValue={data?.data?.data?.name_en}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Select
              required
              label={t("attendeeTypes")}
              id="attendeeTypes"
              setValue={setValue}
              multiple
              options={ROLES.map((role) => ({
                label: t(role),
                value: role,
              }))}
              value={watch("attendeeTypes") || []}
              error={errors?.["attendeeTypes"]?.message}
              defaultValue={data?.data?.data?.attendeeTypes}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data) {
  let body = { ...data };
  if (body.attendeeTypes?.length > 0) {
    body.attendeeTypes = body.attendeeTypes.map((item) => item.value);
  }
  return body;
}

const OrganizationTypeForm = withHookForm({
  title: "createOrgType",
  submitText: "createOrgType",
  validationSchema: organizationTypeSchema(),
  mutationOptionFn: getOrgTypeMutationOptions,
  transformData,
})(OrganizationFormElement);

const OrganizationTypeUpdateForm = withHookForm({
  title: "updateOrgType",
  submitText: "updateOrgType",
  validationSchema: organizationTypeSchema(),
  mutationOptionFn: getOrgTypeMutationOptions,
  transformData,
})(OrganizationFormElement);

export { OrganizationTypeUpdateForm };

export default OrganizationTypeForm;
