import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getDepartmentMutationOptions } from "../../../../api/mutationOptions";
import { getDepartmentQueryOptions } from "../../../../api/queryOptions";
import TableActions from "../../../../components/TableActions";
import useApp from "../../../../hooks/useApp";
import List from "../../../../layouts/List";
export default function Departments() {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const navigate = useNavigate();

  const { user } = useApp();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
      {
        accessorKey: "address",
        header: t("address"),
      },
      {
        accessorKey: "organization.name",
        header: t("organization"),
      },
      {
        accessorKey: "device",
        header: t("device"),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(
                    `/management/organization/${row.original.organization.id}/department/edit/${row.original.id}`
                  ),
              },
              delete: {
                mutationOptionFn: getDepartmentMutationOptions,
                mutationState: {
                  orgId: user?.organization,
                },
                onClick: (mutate) => {
                  mutate &&
                    mutate({
                      mutationMode: "delete",
                      id: row.original.id,
                    });
                },
              },
              view: {
                onClick: () =>
                  navigate(
                    `/management/department/${
                      row.original.id
                    }/attendees/1?roles=${row.original.attendeeTypes.join(
                      ","
                    )}&organization=${orgId}`
                  ),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, user?.organization, orgId]
  );

  return (
    <Box p={2}>
      <List
        withBackButton
        title="departments"
        columns={columns}
        create={{
          title: "createDepartment",
          path: `/management/organization/${
            orgId || user?.organization?.id
          }/department/create`,
        }}
        queryOptionFn={getDepartmentQueryOptions}
        queryState={{
          organization:
            user?.role === "organizationAdmin" ? user?.organization : orgId,
        }}
      />
    </Box>
  );
}
