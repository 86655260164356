import axios from "axios";
import { getStorage } from "../config/localStorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getStorage("access");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // toast.error(error?.message);
    return Promise.reject(error);
  }
);

export function login(data) {
  return axiosInstance.post("/login/", data);
}

export function getMe() {
  return axiosInstance.get("/user/me/");
}

export function getDashStats() {
  return axiosInstance.get("/stats/");
}

export function readFiscalYears() {
  return axiosInstance.get("/fiscal-year/");
}

export function getSingleFiscalYear(id) {
  return () => axiosInstance.get(`/fiscal-year/${id}/`);
}

export function getActiveFiscalYear() {
  return axiosInstance.get(`/fiscal-year/active/`);
}

export function createFiscalYear(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/fiscal-year/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/fiscal-year/${data?.id}/`);
    default:
      return axiosInstance.post("/fiscal-year/", body);
  }
}

export function readShifts(query = null) {
  return () => axiosInstance.get(`/shifts${query || ""}`);
}

export function readShift(id) {
  return () => axiosInstance.get(`/shifts/${id}/`);
}

export function mutateShift(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/shifts/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/shifts/${data?.id}/`);
    default:
      return axiosInstance.post("/shifts/", body);
  }
}

export function readOrganizationTypes() {
  return axiosInstance.get("/organization-type/");
}

export function readOrganizationType(id) {
  return () => axiosInstance.get(`/organization-type/${id}/`);
}

export function mutateOrganizationType(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/organization-type/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/organization-type/${data?.id}/`);
    default:
      return axiosInstance.post("/organization-type/", body);
  }
}

export function readOrganizations() {
  return axiosInstance.get("/organizations/?type=organization");
}

export function readDepartments() {
  return axiosInstance.get("/organizations/?type=department");
}

export function readDeptOfOrganization(id) {
  return () => axiosInstance.get(`/organizations/${id}/departments/`);
}

export function readOrganization(id) {
  return () => axiosInstance.get(`/organizations/${id}`);
}

export function mutateOrganization(data) {
  let body = { ...data };

  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(
        `/organizations/${data?.id}/`,
        body?.formData || body
      );
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/organizations/${data?.id}/`);
    default:
      return axiosInstance.post("/organizations/", body?.formData || body);
  }
}

export function readUsers(query) {
  return () => axiosInstance.get(`/user${query || ""}`);
}

export function readUser(id) {
  return () => axiosInstance.get(`/user/${id}/`);
}

export function mutateUsers(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/user/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/user/${data?.id}/`);
    default:
      return axiosInstance.post("/user/", body);
  }
}

export function readAttendee(role = "student", query = "") {
  return () => axiosInstance.get(`/profile/${role}${query || ""}`);
}

export function mutateAttendee(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(
        `/profile/${data?.role}/${data?.id}/`,
        data.formData
      );
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/profile/${data?.role}/${data?.id}/`);
    case "verify":
      return axiosInstance.post(
        `/profile/${data?.role}/${data?.id}/verify/`,
        body.formData
      );
    default:
      return axiosInstance.post(`/profile/${data?.role}/`, body.formData);
  }
}
export function readDevices(type = "rfid") {
  return () => axiosInstance.get(`/devices?type=${type}`);
}

export function readDevice(id) {
  return () => axiosInstance.get(`/devices/${id}/`);
}

export function mutateDevice(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/devices/${data?.id}/`, body);
    case "delete":
      return axiosInstance.delete(`/devices/${data?.id}/`);
    default:
      return axiosInstance.post(`/devices/`, data);
  }
}

export function syncDevice(serialNumber) {
  return axiosInstance.post(`/devices/${serialNumber}/sync-user/`);
}

export function readActivityLogs(query) {
  return () => axiosInstance.get(`/attendance-log${query || ""}`);
}

export function readMonthlyReportSheet(query) {
  return () => axiosInstance.get(`/report/attendance${query || ""}`);
}

export function readIndividualReport(id, query) {
  return () =>
    axiosInstance.get(`/report/attendance/${id}/individual${query || ""}`);
}

export function readAbsentList(query) {
  return () => axiosInstance.get(`/report/absence${query || ""}`);
}
export function readAbsenClasswise() {
  return () => axiosInstance.get(`/stats/classwise`);
}

export function readClasses(query = null) {
  return () => axiosInstance.get(`/class${query || ""}`);
}

export function readClass(id) {
  return () => axiosInstance.get(`/class/${id}`);
}

export function mutateClass(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/class/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/class/${data?.id}/`);
    default:
      return axiosInstance.post("/class/", body);
  }
}

export function readHolidays(query = null) {
  return () => axiosInstance.get(`/holiday${query || ""}`);
}

export function readHoliday(id) {
  return () => axiosInstance.get(`/holiday/${id}`);
}

export function mutateHoliday(data) {
  let body = { ...data };
  switch (data?.mutationMode) {
    case "patch":
      delete body.id;
      delete body.mutationMode;
      return axiosInstance.patch(`/holiday/${data?.id}/`, body);
    case "delete":
      delete body.mutationMode;
      delete body.id;
      return axiosInstance.delete(`/holiday/${data?.id}/`);
    default:
      return axiosInstance.post("/holiday/", body);
  }
}

export default axiosInstance;
