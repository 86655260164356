/**
 * @module utils/isEmpty
 */

/**
 * @function isEmpty
 * @description Checks if an object is empty
 * @param {object} obj Object to be checked
 * @returns {boolean} true if the object is empty
 */
export default function isEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}
