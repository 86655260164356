import { createBrowserRouter } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/Dashboard";
import ErrorBoundary from "./pages/ErrorBoundary";
import ActivityLog from "./pages/activitylog";
import Login from "./pages/auth/login";
import Dashboard from "./pages/dashboard";
import Devices from "./pages/manangement/device";
import DeviceForm, { DeviceUpdateForm } from "./pages/manangement/device/form";
import FiscalYear from "./pages/manangement/fiscalYear";
import FiscalYearForm, {
  FiscalYearUpdateForm,
} from "./pages/manangement/fiscalYear/form";
import Organizations from "./pages/manangement/organization";
import Departments from "./pages/manangement/organization/department";
import Attendees from "./pages/manangement/organization/department/attendee";
import AttendeeForm, {
  AttendeeUpdateForm,
  AttendeeVerifyForm,
} from "./pages/manangement/organization/department/attendee/form";
import DepartmentForm, {
  DepartmentUpdateForm,
} from "./pages/manangement/organization/department/form";
import OrganizationForm, {
  OrganizationUpdateForm,
} from "./pages/manangement/organization/form";
import Shifts from "./pages/manangement/shift";
import ShiftForm, { ShiftUpdateForm } from "./pages/manangement/shift/form";
import Users from "./pages/manangement/user";
import UserForm, { UserUpdateForm } from "./pages/manangement/user/form";

import withPermission from "./hocs/withPermission";
import AllAttendee from "./pages/Attendees/AllAttendee";
import Class from "./pages/manangement/class";
import StudentListFromClass from "./pages/manangement/class/StudentListFromClass";
import ClassForm, { ClassUpdateForm } from "./pages/manangement/class/form";
import Holiday from "./pages/manangement/holiday";
import HolidayForm, {
  HolidayUpdateForm,
} from "./pages/manangement/holiday/form";
import OrganizationType from "./pages/manangement/orgType";
import OrganizationTypeForm, {
  OrganizationTypeUpdateForm,
} from "./pages/manangement/orgType/form";
import PrivacyPolicy from "./pages/privacypolicy";
import AbsentList from "./pages/reports/AbsentList";
import IndividualReport from "./pages/reports/IndividualReport";
import MonthlyReportSheet from "./pages/reports/MonthlyReportSheet";

/**
 * @typedef {Object} RouteObject
 * @property {string} [path] - The path pattern for the route.
 * @property {boolean} [index] - Whether this is an index route.
 * @property {RouteObject[]} [children] - Child routes for nested routing.
 * @property {boolean} [caseSensitive] - Whether the matching should be case-sensitive.
 * @property {string} [id] - The id of the route.
 * @property {LoaderFunction} [loader] - The loader function for the route, if any.
 * @property {ActionFunction} [action] - The action function for the route, if any.
 * @property {React.ReactNode | null} [element] - The component to render for this route.
 * @property {React.ReactNode | null} [hydrateFallbackElement] - The hydrate fallback element.
 * @property {React.ReactNode | null} [errorElement] - The component to render when an error occurs.
 * @property {React.ComponentType | null} [Component] - The component to render for this route.
 * @property {React.ComponentType | null} [HydrateFallback] - The hydrate fallback component.
 * @property {React.ComponentType | null} [ErrorBoundary] - The error boundary component.
 * @property {RouteObject[]} [handle] - The handle for the route.
 * @property {ShouldRevalidateFunction} [shouldRevalidate] - The should revalidate function.
 * @property {LazyRouteFunction<RouteObject>} [lazy] - The lazy route function.
 *
 */

/**
 * @name routes
 * @description The routes configuration for the application.
 * @type {RouteObject[]}
 */
export const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "/activity-log",
        element: <ActivityLog />,
      },
      {
        path: "/attendees/all",
        element: withPermission("view:allAttendee")(AllAttendee),
      },
      {
        path: "/attendee/verify/:id",
        element: withPermission("verify:attendee")(AttendeeVerifyForm),
      },
      {
        path: "/management/fiscalYears/:page",
        element: withPermission("view:fiscalYears")(FiscalYear),
      },
      {
        path: "/management/fiscalYear/create",
        element: <FiscalYearUpdateForm />,
      },
      {
        path: "/management/fiscalYear/edit/:id",
        element: <FiscalYearForm />,
      },
      {
        path: "/management/shifts/:page",
        element: <Shifts />,
      },
      {
        path: "/management/shift/create",
        element: <ShiftForm />,
      },
      {
        path: "/management/shift/edit/:id",
        element: <ShiftUpdateForm />,
      },
      {
        path: "/management/organization-types/:page",
        element: withPermission("view:organizationTypes")(OrganizationType),
      },
      {
        path: "/management/organization-type/create",
        element: <OrganizationTypeForm />,
      },
      {
        path: "/management/organization-type/edit/:id",
        element: <OrganizationTypeUpdateForm />,
      },
      {
        path: "/management/organizations/:page",
        element: withPermission("view:organizations")(Organizations),
      },
      {
        path: "/management/organization/create",
        element: <OrganizationForm />,
      },
      {
        path: "/management/organization/edit/:id",
        element: <OrganizationUpdateForm />,
      },

      {
        path: "/management/organization/:orgId/departments/:page",
        element: <Departments />,
      },
      {
        path: "/management/departments/:page",
        element: <Departments />,
      },
      {
        path: "/management/organization/:orgId/department/create",
        element: <DepartmentForm />,
      },
      {
        path: "/management/organization/:orgId/department/edit/:id",
        element: <DepartmentUpdateForm />,
      },
      {
        path: "/management/holidays/:page",
        element: withPermission("view:holidays")(Holiday),
      },
      {
        path: "/management/holiday/create",
        element: <HolidayForm />,
      },
      {
        path: "/management/holiday/edit/:id",
        element: <HolidayUpdateForm />,
      },
      {
        path: "/management/classes/:page",
        element: withPermission("view:classes")(Class),
      },
      {
        path: "/management/class/create",
        element: <ClassForm />,
      },
      {
        path: "/management/class/edit/:id",
        element: <ClassUpdateForm />,
      },
      {
        path: "/management/class/students/:classId",
        element: <StudentListFromClass />,
      },
      {
        path: "/management/devices/:page",
        element: withPermission("view:devices")(Devices),
      },
      {
        path: "/management/device/create",
        element: <DeviceForm />,
      },
      {
        path: "/management/device/edit/:id",
        element: <DeviceUpdateForm />,
      },
      {
        path: "/management/users/:page",
        element: withPermission("view:users")(Users),
      },
      {
        path: "/management/user/create",
        element: <UserForm />,
      },
      {
        path: "/management/user/edit/:id",
        element: <UserUpdateForm />,
      },
      {
        path: "/management/department/:deptId/attendees/1",
        element: <Attendees />,
      },
      {
        path: "/management/department/:deptId/attendee/create",
        element: <AttendeeForm />,
      },
      {
        path: "/management/department/:deptId/attendee/edit/:id",
        element: <AttendeeUpdateForm />,
      },
      /*
      *
      reports
      *
      */
      {
        path: "/reports/monthly-report/sheet/:id",
        element: <MonthlyReportSheet />,
      },
      {
        path: "/reports/individual-report",
        element: <IndividualReport />,
      },
      {
        path: "/reports/absent-list",
        element: <AbsentList />,
      },
    ],
  },
  {
    path: "/auth/login",
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorBoundary />,
  },
];

const router = createBrowserRouter(routes);

export default router;
