/**
 * @module utils/queryGenerator
 */
import isEmpty from "./isEmpty";
/**
 * @function queryGenerator
 * @param {object} query Query object
 * @returns {string} Query string
 */
export default function queryGenerator(query = {}) {
  if (isEmpty(query)) return "";
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      return acc === "?" ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
    }
    return acc;
  }, "?");
}
