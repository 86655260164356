import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUserMutationOptions } from "../../../api/mutationOptions";
import { getUserQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import useApp from "../../../hooks/useApp";
import List from "../../../layouts/List";
export default function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const app = useApp();

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: t("fullname"),
        cell: ({ row }) =>
          `${row.original.first_name} ${row.original.last_name}`,
      },
      {
        accessorKey: "username",
        header: t("username"),
      },
      {
        accessorKey: "email",
        header: t("email"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
      {
        accessorKey: "role",
        header: t("role"),
      },
      ...(app?.user?.role === "admin"
        ? [
            {
              accessorFn: (row) => row.organization?.name,
              header: t("organization"),
            },
          ]
        : []),
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/user/edit/${row.original.id}/`),
              },
              delete: {
                mutationOptionFn: getUserMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
              view: {},
            }}
          />
        ),
      },
    ],
    [t, navigate, app?.user?.role]
  );

  return (
    <Box p={2}>
      <List
        title="users"
        columns={columns}
        create={{
          title: "createUser",
          path: "/management/user/create",
        }}
        queryOptionFn={getUserQueryOptions}
        queryState={{ role: "admin,organizationAdmin" }}
      />
    </Box>
  );
}
