/**
 * @module utils/pick
 */

/**
 * @function pick
 * @description Pick keys from an object
 * @param {object} obj
 * @param {string[]} keys
 * @returns {object}
 */
export default function pick(obj, keys) {
  return keys.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
