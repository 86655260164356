import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getDeviceMutationOptions,
  getDeviceSyncMutationOptions,
} from "../../../api/mutationOptions";
import { getDevicesQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import useApp from "../../../hooks/useApp";
import List from "../../../layouts/List";
export default function ATMDDeviceList() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const app = useApp();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "serial_number",
        header: t("serialNumber"),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/device/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getDeviceMutationOptions,
                onClick: (mutate) => {
                  mutate &&
                    mutate({
                      mutationMode: "delete",
                      id: row.original.id,
                    });
                },
              },

              ...(app?.user?.role === "admin" && {
                sync: {
                  mutationOptionFn: getDeviceSyncMutationOptions,
                  onClick: (mutate) => {
                    mutate && mutate(row.original.serial_number);
                  },
                },
              }),
            }}
          />
        ),
      },
    ],
    [t, navigate, app?.user?.role]
  );

  return (
    <Box p={2}>
      <List
        title="atmd"
        columns={columns}
        create={{
          title: "createDevice",
          path: "/management/device/create?type=zkteco",
          permissions: ["createDevice"],
        }}
        queryOptionFn={getDevicesQueryOptions}
        queryState={{ type: "zkteco" }}
      />
    </Box>
  );
}
