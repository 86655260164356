import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getAttendeeMutationOptions } from "../../../../../api/mutationOptions";
import { getAttendeeQueryOptions } from "../../../../../api/queryOptions";
import TableActions from "../../../../../components/TableActions";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import List from "../../../../../layouts/List";
export default function TeacherList() {
  const { t } = useTranslation();
  const { deptId } = useParams();
  const [organization] = useUrlQuery(["organization"]);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: t("name"),
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar
                alt={row.original?.first_name + " " + row.original?.last_name}
                src={row.original.image}
              />
              <Stack>
                <Typography>
                  {row.original?.first_name + " " + row.original?.last_name}
                </Typography>
                <Typography variant="body2">
                  {row.original?.username}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        accessorKey: "card_number",
        header: t("cardNo"),
      },
      {
        accessorKey: "status",
        header: t("status"),
      },
      {
        accessorKey: "shift.title",
        header: t("shift"),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(
                    `/management/department/${deptId}/attendee/edit/${row.original.id}?role=teacher&organization=${organization}`
                  ),
              },
              delete: {
                mutationOptionFn: getAttendeeMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                    role: "teacher",
                  }),
              },

              view: {},
            }}
          />
        ),
      },
    ],
    [t, deptId, navigate, organization]
  );

  return (
    <Box p={2}>
      <List
        title="teachers"
        columns={columns}
        create={[
          {
            id: "createTeacher",
            title: "createTeacher",
            path: `/management/department/${deptId}/attendee/create?role=teacher&organization=${organization}`,
          },
        ]}
        queryOptionFn={getAttendeeQueryOptions}
        queryState={{ role: "teacher", department: deptId }}
      />
    </Box>
  );
}
