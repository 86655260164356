import { Box } from "@mui/material";
import DateComponent from "./Date";
import FileComponent from "./File";
import Select from "./Select";
import SwitchComponent from "./Switch";
import Text from "./Text";

function FormWrapper({ Component, ...rest }) {
  return (
    <Box p={1}>
      <Component {...rest} />
    </Box>
  );
}

const FormElement = {
  Text: (props) => <FormWrapper Component={Text} {...props} />,
  Select: (props) => <FormWrapper Component={Select} {...props} />,
  Switch: (props) => <FormWrapper Component={SwitchComponent} {...props} />,
  File: (props) => <FormWrapper Component={FileComponent} {...props} />,
  Date: (props) => <FormWrapper Component={DateComponent} {...props} />,
};

export default FormElement;
