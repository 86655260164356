import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getClassMutationOptions } from "../../../api/mutationOptions";
import { getClassQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import useApp from "../../../hooks/useApp";
import List from "../../../layouts/List";
export default function Class() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const app = useApp();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "grade_text",
        header: t("grade"),
      },
      {
        accessorKey: "section_text",
        header: t("section"),
      },
      ...(app?.user?.role === "admin"
        ? [
            {
              accessorKey: "organization.name",
              header: t("organization"),
            },
          ]
        : []),
      {
        accessorKey: "studentCount",
        header: t("studentCount"),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/class/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getClassMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
              view: {
                onClick: () =>
                  navigate(
                    `/management/class/students/${row.original.id}?classname=${row.original.name}`
                  ),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, app?.user?.role]
  );

  return (
    <Box p={2}>
      <List
        tableSize="small"
        title="classes"
        columns={columns}
        create={{
          title: "createClass",
          path: "/management/class/create",
        }}
        queryOptionFn={getClassQueryOptions}
      />
    </Box>
  );
}
