const nepaliNumbers = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

/**
 * @module utils/convertToNepaliNumber
 */

/**
 * @method convertToNepaliNumber
 * @name convertToNepaliNumber
 * @description Converts English number to Nepali number
 * @param {string} englishNumber English number to be converted into Nepali number
 * @returns {string} Nepali number
 */
export default function nepaliNumberConverter(numString = "") {
  let nepaliNumber = "";
  for (let i = 0; i < numString.length; i++) {
    if (nepaliNumbers.includes(numString[i])) {
      nepaliNumber += numString[i];
    } else if (nepaliNumbers[numString[i]]) {
      nepaliNumber += nepaliNumbers[numString[i]];
    } else {
      nepaliNumber += numString[i];
    }
  }
  return nepaliNumber;
}
