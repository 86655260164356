import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
} from "@mui/material";

export default function SwitchComponent({
  id,
  value,
  setValue,
  onChange = () => {},
  label,
  error,
  ...props
}) {
  function handleChange(event) {
    setValue(id, event.target.checked);
    onChange(event.target.checked);
  }
  return (
    <Box position={"relative"}>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            {...(error && { color: "error" })}
            control={
              <Switch checked={value} onChange={handleChange} {...props} />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
}
