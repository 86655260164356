import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAttendeeQueryOptions } from "../../../../../api/queryOptions";
import TableActions from "../../../../../components/TableActions";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import List from "../../../../../layouts/List";
export default function EmployeeList() {
  const { t } = useTranslation();
  const { deptId } = useParams();
  const [organization] = useUrlQuery(["organization"]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: t("name"),
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar
                alt={row.original?.first_name + " " + row.original?.last_name}
                src={row.original.image}
              />
              <Typography>
                {row.original?.first_name + " " + row.original?.last_name}
              </Typography>
            </Stack>
          );
        },
      },
      {
        accessorKey: "card_number",
        header: t("cardNo"),
      },
      {
        accessorKey: "status",
        header: t("status"),
      },
      {
        accessorKey: "shift.title",
        header: t("shift"),
      },
      {
        header: t("actions"),
        cell: () => (
          <TableActions actions={{ edit: {}, delete: {}, view: {} }} />
        ),
      },
    ],
    [t]
  );

  return (
    <Box p={2}>
      <List
        title="employees"
        columns={columns}
        create={[
          {
            id: "createEmployee",
            title: "createEmployee",
            path: `/management/department/${deptId}/attendee/create?role=employee&organization=${organization}`,
          },
        ]}
        queryOptionFn={getAttendeeQueryOptions}
        queryState={{ role: "employee", department: deptId }}
      />
    </Box>
  );
}
