import { NewReleases, Verified } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  readClasses,
  readDeptOfOrganization,
  readOrganizations,
} from "../../api";
import { getUserMutationOptions } from "../../api/mutationOptions";
import { getUserQueryOptions } from "../../api/queryOptions";
import FormElement from "../../components/form";
import TableActions from "../../components/TableActions";
import { ROLES, USER_STATUS } from "../../constants";
import useApp from "../../hooks/useApp";
import List from "../../layouts/List";
import queryGenerator from "../../utils/queryGenerator";

export default function AllAttendee() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = (data) => () => setOpen(data);
  const handleClose = () => setOpen(false);

  const app = useApp();

  const defaultFilters = {
    organization: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const { data: orgData, isLoading: isOrgLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
    enabled: app?.user?.role === "admin",
  });

  const { data: deptData, isLoading: isDeptLoading } = useQuery({
    queryKey: ["department", filters.organization],
    queryFn: readDeptOfOrganization(filters.organization),
    enabled: !!filters.organization,
  });

  const { data: gradeData, isLoading: isGradeLoading } = useQuery({
    queryKey: ["classes", filters.organization],
    queryFn: readClasses(
      queryGenerator({ organization: filters.organization })
    ),
    enabled: !!filters.organization,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: t("name"),
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar alt={row.original.fullname} src={row.original.image} />
              <Box>
                <Typography>
                  {row.original.fullname ||
                    row.original.first_name + " " + row.original.last_name}
                </Typography>
                <Typography variant="body2">{row.original.role}</Typography>
              </Box>

              {row.original.status === "active" ? (
                <Verified color="success" fontSize="12" />
              ) : (
                <NewReleases color="warning" fontSize="12" />
              )}
            </Stack>
          );
        },
      },
      {
        accessorKey: "card_number",
        header: t("cardNo"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
      {
        accessorKey: "department.name",
        header: t("department"),
      },
      {
        accessorKey: "status",
        header: t("status"),
        cell: ({ row }) => (
          <Typography color={USER_STATUS[row.original.status]?.color}>
            {t(USER_STATUS[row.original.status]?.translationKey)}
          </Typography>
        ),
        meta: {
          filterVariant: "select",
        },
      },
      {
        accessorKey: "shift.name",
        header: t("shift"),
      },

      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              ...(row.original.status === "inactive"
                ? {
                    activate: {
                      mutationOptionFn: getUserMutationOptions,
                      mutationState: {
                        navigateTo: "/attendees/all",
                      },
                      onClick: (mutate) => {
                        mutate &&
                          mutate({
                            mutationMode: "patch",
                            id: row.original.id,
                            status: "active",
                          });
                      },
                    },
                  }
                : row.original.status === "not_verified"
                ? {
                    verify: {
                      onClick: handleOpen(row.original),
                    },
                  }
                : {
                    deActivate: {
                      mutationOptionFn: getUserMutationOptions,
                      mutationState: {
                        navigateTo: "/attendees/all",
                      },
                      onClick: (mutate) => {
                        mutate &&
                          mutate({
                            mutationMode: "patch",
                            id: row.original.id,
                            status: "inactive",
                          });
                      },
                    },
                  }),
            }}
          />
        ),
      },
    ],
    [t]
  );

  const FilterComponents = [
    ...(app?.user?.role === "admin"
      ? [
          <FormElement.Select
            label={t("organization")}
            key={"organization"}
            options={orgData?.data?.data?.results?.map((org) => ({
              value: org?.id,
              label: org?.name,
            }))}
            isLoading={isOrgLoading}
            onChange={(value) => {
              setFilters((prev) => ({ ...prev, organization: value?.value }));
            }}
          />,
        ]
      : []),
    <FormElement.Select
      label={t("department")}
      key={"department"}
      options={deptData?.data?.data?.results?.map((dept) => ({
        value: dept?.id,
        label: dept?.name,
      }))}
      isLoading={isDeptLoading}
      disabled={!filters.organization}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, department: value?.value }));
      }}
    />,
    <FormElement.Select
      label={t("role")}
      key={"role"}
      options={ROLES.map((role) => ({
        value: role,
        label: t(role),
      }))}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, role: value?.value }));
      }}
    />,
    <FormElement.Select
      label={t("grade")}
      key={"grade"}
      options={gradeData?.data?.data?.results?.map((grade) => ({
        value: grade?.id,
        label: grade?.name,
      }))}
      isLoading={isGradeLoading}
      disabled={!filters.organization}
      onChange={(value) => {
        setFilters((prev) => ({ ...prev, grade: value?.value }));
      }}
    />,
  ];

  useEffect(() => {
    if (app?.user?.role !== "admin") {
      setFilters((prev) => ({
        ...prev,
        organization: app?.user?.organization?.id,
      }));
    }
  }, [app]);

  return (
    <Box p={2}>
      <List
        tableSize="small"
        filters={FilterComponents}
        clearFilter={() => setFilters(defaultFilters)}
        title="attendees"
        columns={columns}
        queryOptionFn={getUserQueryOptions}
        manualPagination={true}
        queryState={{ noPagination: false, ...filters }}
      />
      <VerifyUserModal open={open} handleClose={handleClose} />
    </Box>
  );
}

function VerifyUserModal({ open, handleClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [type, setType] = useState({ label: "Student", value: "student" });

  return (
    <div>
      <Modal
        open={!!open}
        onClose={handleClose}
        aria-labelledby="verify-modal-title"
      >
        <Box
          bgcolor={"background.default"}
          sx={{ p: 2, width: "30vw", mx: "auto", mt: 4 }}
        >
          <Typography
            id="verify-modal-title"
            color="primary"
            variant="h6"
            component="h2"
          >
            {t("verifyUserModalTitle")}
          </Typography>
          <Stack
            sx={{
              p: 2,
              backgroundColor: "background.paper",
              mt: 2,
              rowGap: 2,
            }}
          >
            <Autocomplete
              options={[
                { value: "student", label: "Student" },
                { label: "Teacher", value: "teacher" },
                { label: "Employee", value: "employee" },
              ]}
              value={type}
              onChange={(event, newValue) => {
                setType(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Verify as:" />
              )}
            />
            <Button
              variant="contained"
              sx={{ marginLeft: "auto" }}
              onClick={() =>
                navigate(`/attendee/verify/${open?.id}?role=${type.value}`)
              }
            >
              {t("proceedToVerification")}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
