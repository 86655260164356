import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDeviceMutationOptions } from "../../../api/mutationOptions";
import { getDevicesQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import List from "../../../layouts/List";
import { MODES } from "./data";
export default function RFIDDeviceList() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "config.baudrate",
        header: t("baudRate"),
      },
      {
        accessorKey: "config.max_frequency",
        header: t("maxFrequency"),
      },
      {
        accessorKey: "config.min_frequency",
        header: t("minFrequency"),
      },
      {
        accessorKey: "serial_number",
        header: t("serialNumber"),
      },
      {
        accessorKey: "config.mode",
        header: t("mode"),
        cell: ({ row }) =>
          MODES.find((mode) => mode.value === row.original?.config?.mode)
            ?.label,
      },
      {
        accessorKey: "config.power",
        header: t("power"),
      },
      {
        accessorKey: "config.protocol",
        header: t("protocol"),
      },
      {
        accessorKey: "config.type",
        header: t("type"),
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/device/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getDeviceMutationOptions,
                onClick: (mutate) => {
                  mutate &&
                    mutate({
                      mutationMode: "delete",
                      id: row.original.id,
                    });
                },
              },
            }}
          />
        ),
      },
    ],
    [t, navigate]
  );

  return (
    <Box p={2}>
      <List
        title="rfid"
        columns={columns}
        create={{
          title: "createDevice",
          path: "/management/device/create?type=rfid",
          permissions: "create:device",
        }}
        queryOptionFn={getDevicesQueryOptions}
        queryState={{ type: "rfid" }}
      />
    </Box>
  );
}
