/**
 * @module hocs/withPermission
 */
import { Navigate } from "react-router-dom";
import { getStorage } from "../config/localStorage";
import { hasPermissions } from "../config/permissions";

/**
 * @function withPermission(permissions)
 *
 * @description A Higher Order Component that checks if the user has the required permissions
 * before rendering the wrapped component.
 *
 * @param {string | string[]} permissions - The required permissions.
 *
 * @returns {React.FC} A Higher Order Component that checks for the required permissions.
 *
 * @example
 * import withPermission from "./withPermission";
 *
 * const MyComponent = () => {
 *   // ...
 * };
 *
 * export default withPermission(["view:users"])(MyComponent);
 */
export default function withPermission(permissions = []) {
  const role = getStorage("role");
  const isPermitted = hasPermissions(permissions, role);

  if (!isPermitted) {
    return () => <Navigate to="/" />;
  }
  return (Component) => <Component />;
}
