import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonTab from "../../../components/ButtonTab";
import ATMDDeviceList from "./ATMDDeviceList";
import RFIDDeviceList from "./RFIDDeviceList";
export default function Devices() {
  const { t } = useTranslation();
  const DEVICETYPES = {
    atmd: t("atmd"),
    rfid: t("rfid"),
  };
  const deviceListComponent = {
    rfid: <RFIDDeviceList />,
    atmd: <ATMDDeviceList />,
  };
  const [deviceType, setDeviceType] = useState("atmd");
  return (
    <Box p={2}>
      <ButtonTab
        state={deviceType}
        setState={setDeviceType}
        buttons={DEVICETYPES}
      />
      {deviceListComponent[deviceType]}
    </Box>
  );
}
