import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getFiscalYearMutationOptions } from "../../../api/mutationOptions";
import { getFiscalYearQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import List from "../../../layouts/List";
import nepaliNumberConverter from "../../../utils/convertToNepaliNumber";
export default function FiscalYear() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: t("title"),
      },
      {
        accessorKey: "is_active",
        header: `${t("isActive")}?`,
        accessorFn: (row) => (row.is_active ? t("yes") : t("no")),
        meta: { filterVariant: "select" },
      },

      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/fiscalYear/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getFiscalYearMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
              ...(row.original.is_active
                ? {
                    deActivate: {
                      mutationOptionFn: getFiscalYearMutationOptions,
                      onClick: (mutate) =>
                        mutate &&
                        mutate({
                          mutationMode: "patch",
                          id: row.original.id,
                          is_active: false,
                        }),
                    },
                  }
                : {
                    activate: {
                      mutationOptionFn: getFiscalYearMutationOptions,
                      onClick: (mutate) =>
                        mutate &&
                        mutate({
                          mutationMode: "patch",
                          id: row.original.id,
                          is_active: true,
                        }),
                    },
                  }),
            }}
          />
        ),
      },
    ],
    [t, navigate]
  );

  return (
    <Box p={2}>
      <List
        title="fiscalYear"
        columns={columns}
        create={{
          title: "createFiscalYear",
          path: "/management/fiscalYear/create",
        }}
        queryOptionFn={getFiscalYearQueryOptions}
        transformData={(data) => {
          return data?.data?.data?.results.map((item) => {
            return {
              ...item,
              title:
                i18n.language === "np"
                  ? nepaliNumberConverter(item.title)
                  : item.title,
            };
          });
        }}
      />
    </Box>
  );
}
