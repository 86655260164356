import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Divider,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { login } from "../../api";
import FormElement from "../../components/form";
import { setStorage } from "../../config/localStorage";
import useApp from "../../hooks/useApp";
import { loginSchema } from "../../validations/schema";

export default function Login() {
  const { t } = useTranslation();
  const app = useApp();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["login"],
    mutationFn: login,
    onSuccess: (res) => {
      setStorage("access", res?.data?.data?.access);
      setStorage("refresh", res?.data?.data?.refresh);
      setStorage("role", res?.data?.data?.role);
      app.setUser(res?.data?.data);
      navigate("/");
    },
  });

  function onSubmit(data) {
    mutate(data);
  }

  useEffect(() => {
    if (app?.user) {
      navigate("/");
    }
  }, [app?.user, navigate]);

  return (
    <Stack
      height={"100vh"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"background.default"}
    >
      <Stack
        p={2}
        minWidth={"30%"}
        position={"relative"}
        sx={{ isolation: "isolate" }}
        borderRadius={4}
      >
        <img
          src="/logo.png"
          alt="brand"
          style={{
            width: "20%",
            alignSelf: "center",
            marginBlockEnd: "1.5rem",
          }}
        />
        <Typography variant="h5" color="primary" textAlign={"center"}>
          {t("attendance")}
        </Typography>
        <Divider sx={{ my: 2, color: "primary.main" }}>{t("login")}</Divider>
        <FormElement.Text
          label={t("username")}
          register={register}
          id="username"
          error={errors?.username?.message}
        />
        <FormElement.Text
          label={t("password")}
          register={register}
          id="password"
          error={errors?.password?.message}
          type="password"
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Button sx={{ fontSize: 12 }}>{t("forgotPassword")}</Button>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Checkbox />
          <FormLabel>{t("rememberMe")}</FormLabel>
        </Stack>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {t("login")}
        </Button>
      </Stack>
    </Stack>
  );
}
