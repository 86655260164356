import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readDevice, readOrganizations } from "../../../api";
import { getDeviceMutationOptions } from "../../../api/mutationOptions";
import FormElement from "../../../components/form";
import withHookForm from "../../../hocs/withHookForm";
import useApp from "../../../hooks/useApp";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { deviceSchema } from "../../../validations/schema";
import { MODES } from "./data";

function DeviceFormElement({
  register,
  setValue,
  formState: { errors },
  editId,
}) {
  const { t } = useTranslation();

  const type = useUrlQuery("type");

  const { data, isLoading } = useQuery({
    queryKey: ["device", editId],
    queryFn: readDevice(editId),
    enabled: !!editId,
  });

  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });

  const App = useApp();

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={3}>
            <FormElement.Text
              label={t("name")}
              register={register}
              required
              id="name"
              error={errors?.["name"]?.message}
              defaultValue={data?.data?.data?.name}
            />
          </Grid2>
          <Grid2 sm={3}>
            <FormElement.Text
              label={t("serialNumber")}
              register={register}
              required
              id="serial_number"
              error={errors?.["serial_number"]?.message}
              defaultValue={data?.data?.data?.serial_number}
            />
          </Grid2>
          {type === "rfid" && (
            <>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("baudRate")}
                  register={register}
                  type="number"
                  required
                  id="config.baudrate"
                  error={errors?.["baudrate"]?.message}
                  defaultValue={data?.data?.data?.baudrate}
                />
              </Grid2>

              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("type")}
                  register={register}
                  required
                  id="config.type"
                  error={errors?.["type"]?.message}
                  defaultValue={data?.data?.data?.type}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("protocol")}
                  register={register}
                  required
                  id="config.protocol"
                  error={errors?.["protocol"]?.message}
                  defaultValue={data?.data?.data?.protocol}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("address")}
                  register={register}
                  required
                  id="config.address"
                  error={errors?.["address"]?.message}
                  defaultValue={data?.data?.data?.address}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("power")}
                  register={register}
                  required
                  type="number"
                  id="config.power"
                  error={errors?.["power"]?.message}
                  defaultValue={data?.data?.data?.power}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("maxScanTime")}
                  register={register}
                  type="number"
                  required
                  id="config.max_scan_time"
                  error={errors?.["max_scan_time"]?.message}
                  defaultValue={data?.data?.data?.max_scan_time}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("minFrequency")}
                  register={register}
                  type="number"
                  required
                  id="config.min_frequency"
                  error={errors?.["min_frequency"]?.message}
                  defaultValue={data?.data?.data?.min_frequency}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("maxFrequency")}
                  register={register}
                  type="number"
                  required
                  id="config.max_frequency"
                  error={errors?.["max_frequency"]?.message}
                  defaultValue={data?.data?.data?.max_frequency}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Select
                  label={t("mode")}
                  required
                  setValue={setValue}
                  id="config.mode"
                  options={MODES}
                  error={errors?.["mode"]?.message}
                  defaultValue={MODES.find(
                    (mode) => mode.value === data?.data?.data?.mode
                  )}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("version")}
                  register={register}
                  required
                  id="config.version"
                  error={errors?.["version"]?.message}
                  defaultValue={data?.data?.data?.version}
                />
              </Grid2>
              <Grid2 sm={3}>
                <FormElement.Text
                  label={t("subVersion")}
                  register={register}
                  required
                  id="config.sub_version"
                  error={errors?.["sub_version"]?.message}
                  defaultValue={data?.data?.data?.sub_version}
                />
              </Grid2>
            </>
          )}
          {App.user.role === "admin" && (
            <FormElement.Select
              required
              id="organization"
              label={t("organization")}
              setValue={setValue}
              options={orgData?.data?.data?.results?.map((org) => ({
                value: org?.id,
                label: org?.name,
              }))}
              error={errors?.["organization"]?.message}
              defaultValue={data?.data?.data?.organization}
            />
          )}
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data, _, queries) {
  let body = { ...data };
  body.type = queries?.type;
  if (data?.config?.mode?.value) {
    body.config.mode = data.config.mode.value;
  }
  if (data?.organization) {
    body.organization = data?.organization?.value;
  }
  return body;
}

const DeviceForm = withHookForm({
  title: "createDevice",
  submitText: "createDevice",
  validationSchema: deviceSchema(),
  mutationOptionFn: getDeviceMutationOptions,
  transformData,
})(DeviceFormElement);

const DeviceUpdateForm = withHookForm({
  title: "updateDevice",
  submitText: "updateDevice",
  validationSchema: deviceSchema(),
  mutationOptionFn: getDeviceMutationOptions,
  transformData,
})(DeviceFormElement);

export { DeviceUpdateForm };

export default DeviceForm;
