import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = (data) => {
  const classes = data?.data?.data?.map((data) => {
    return data.grade_text;
  });
  const sections = data?.data?.data?.map((data) => {
    return data.section_text;
  });

  function filterDuplicates(arr) {
    const seen = new Set();
    if (arr) {
      return arr.filter((item) => {
        if (!seen.has(item)) {
          seen.add(item);
          return true;
        }
        return false;
      });
    }
  }

  const classData = filterDuplicates(classes);
  const sectionData = filterDuplicates(sections);

  const series = sectionData?.map((section) => {
    return {
      name: section,
      data: classData?.map((grade) => {
        return (
          data?.data?.data
            ?.filter((data) => data.section_text === section)
            .find((data) => data.grade_text === grade)?.usersCount -
          data?.data?.data
            ?.filter((data) => data.section_text === section)
            .find((data) => data.grade_text === grade)?.presentCount
        );
      }),
    };
  });

  let options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Overview",
      align: "left",
    },
    xaxis: {
      categories: classData,
    },
    noData: {
      text: "पर्याप्त डाटा उपलब्ध छैन",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
      },
    },
    yaxis: {
      title: {
        text: "Absent Students",
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default LineChart;
