import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  readDevices,
  readOrganization,
  readOrganizations,
} from "../../../../api";
import { getDepartmentMutationOptions } from "../../../../api/mutationOptions";
import FormElement from "../../../../components/form";
import withHookForm from "../../../../hocs/withHookForm";
import { departmentSchema } from "../../../../validations/schema";

function DepartmentFormElement({
  register,
  formState: { errors },
  setValue,
  watch,
}) {
  const { t } = useTranslation();
  const { orgId, id } = useParams();

  useEffect(() => {
    if (orgId) {
      setValue("organization", orgId);
      setValue("type", "department");
    }
  }, [setValue, orgId]);

  const { data, isLoading } = useQuery({
    queryKey: ["department", id],
    queryFn: readOrganization(id),
    enabled: !!id,
  });

  const { data: orgData, isOrgLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: readOrganizations,
  });

  const { data: deviceData, isLoading: deviceLoading } = useQuery({
    queryKey: ["devices"],
    queryFn: readDevices("zkteco"),
  });

  return (
    <Stack rowGap={4}>
      {!isLoading && (
        <Grid2 container>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("name")}
              required
              register={register}
              id="name"
              error={errors?.["name"]?.message}
              defaultValue={data?.data?.data?.name}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("phone")}
              required
              type="tel"
              register={register}
              id="phone"
              error={errors?.["phone"]?.message}
              defaultValue={data?.data?.data?.phone}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Text
              label={t("address")}
              required
              type="address"
              register={register}
              id="address"
              error={errors?.["address"]?.message}
              defaultValue={data?.data?.data?.address}
            />
          </Grid2>
          {!orgId && (
            <Grid2 sm={4}>
              <FormElement.Select
                label={t("organization")}
                id="organization"
                setValue={setValue}
                options={orgData?.data?.data?.results?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                isLoading={isOrgLoading}
                error={errors?.["organization"]?.message}
                defaultValue={data?.data?.data?.organization}
              />
            </Grid2>
          )}
          <Grid2 sm={4}>
            <FormElement.Select
              required
              label={t("attendeeTypes")}
              id="attendeeTypes"
              setValue={setValue}
              multiple
              options={[
                { label: "Student", value: "student" },
                { label: "Teacher", value: "teacher" },
                { label: "Employee", value: "employee" },
              ]}
              value={watch("attendeeTypes")}
              error={errors?.["attendeeTypes"]?.message}
              defaultValue={data?.data?.data?.attendeeTypes}
            />
          </Grid2>
          <Grid2 sm={4}>
            <FormElement.Select
              label={t("device")}
              id="device"
              multiple
              value={watch("device")}
              setValue={setValue}
              options={deviceData?.data?.data?.results?.map((item) => ({
                label: item?.name,
                value: item?.serial_number,
              }))}
              isLoading={deviceLoading}
              error={errors?.["device"]?.message}
              defaultValue={data?.data?.data?.device}
            />
          </Grid2>
        </Grid2>
      )}
    </Stack>
  );
}

function transformData(data, params) {
  let body = { ...data };
  body.type = "department";
  if (data?.device?.length > 0) {
    body.device = data?.device?.map((item) => item?.value);
  }
  if (body?.attendeeTypes?.length > 0) {
    body.attendeeTypes = data?.attendeeTypes?.map((item) => item?.value);
  }
  if (body?.organization) body.organization = body?.organization?.value;
  if (!body?.organization) body.organization = params.orgId;
  return body;
}

const DepartmentForm = withHookForm({
  title: "createDepartment",
  submitText: "createDepartment",
  validationSchema: departmentSchema(),
  transformData,
  mutationOptionFn: getDepartmentMutationOptions,
})(DepartmentFormElement);

const DepartmentUpdateForm = withHookForm({
  title: "updateDepartment",
  submitText: "updateDepartment",
  validationSchema: departmentSchema(),
  transformData,
  mutationOptionFn: getDepartmentMutationOptions,
})(DepartmentFormElement);

export { DepartmentUpdateForm };

export default DepartmentForm;
