import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getActiveFiscalYear, getDashStats } from "../../api";
import useApp from "../../hooks/useApp";
import GB from "../../svg/GB";
import NP from "../../svg/NP";
import convertToNepaliNumber from "../../utils/convertToNepaliNumber";

export default function Header() {
  const navigate = useNavigate();
  const app = useApp();

  const [anchorEl, setAnchorEl] = useState(null);

  const { t, i18n } = useTranslation();

  function handleLanguageChange() {
    localStorage.setItem(
      "attendance_language",
      i18n.language === "np" ? "en" : "np"
    );
    i18n.changeLanguage(i18n.language === "np" ? "en" : "np");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function logout() {
    app.logout(navigate);
  }

  const { data: activeFiscalYear, isLoading: isFiscalYearLoading } = useQuery({
    queryKey: ["activeFiscalYear"],
    queryFn: getActiveFiscalYear,
    staleTime: Infinity,
  });

  const { data: statsData, isLoading: statsLoading } = useQuery({
    queryKey: ["stats"],
    queryFn: getDashStats,
    staleTime: Infinity,
  });

  return (
    <Box
      p={2}
      position="sticky"
      top={0}
      bgcolor={"background.default"}
      zIndex={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={3}>
          <Typography fontWeight="bold">
            {t("fiscalYear")}:{" "}
            <Typography component={"span"} color={grey[600]} fontWeight="bold">
              {isFiscalYearLoading
                ? "Loading..."
                : i18n.language === "np"
                ? convertToNepaliNumber(activeFiscalYear?.data?.data?.title)
                : activeFiscalYear?.data?.data?.title}
            </Typography>
          </Typography>
          <Typography color="primary.main">
            {t("deviceConnected")}:{" "}
            <Typography component={"span"} color={grey[600]}>
              {statsLoading
                ? "Loading..."
                : i18n.language === "np"
                ? convertToNepaliNumber(
                    statsData?.data?.data?.device?.count?.toString()
                  )
                : statsData?.data?.data?.device?.count}
            </Typography>
          </Typography>
          <Typography color="success.main">
            {t("attendToday")}:{" "}
            <Typography component={"span"} color={grey[600]}>
              {statsLoading
                ? "Loading..."
                : i18n.language === "np"
                ? convertToNepaliNumber(
                    statsData?.data?.data?.attendance?.count?.toString()
                  )
                : statsData?.data?.data?.attendance?.count}
            </Typography>
          </Typography>
          <Typography color="error.main">
            {t("absentToday")}:{" "}
            <Typography component={"span"} color={grey[600]}>
              {statsLoading
                ? "Loading..."
                : i18n.language === "np"
                ? convertToNepaliNumber(
                    (
                      statsData?.data?.data?.totalAttendee?.count -
                      statsData?.data?.data?.attendance?.count
                    ).toString()
                  )
                : statsData?.data?.data?.totalAttendee?.count -
                  statsData?.data?.data?.attendance?.count}
            </Typography>
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={3}>
          <Button onClick={handleLanguageChange}>
            {i18n.language === "np" ? <NP size={18} /> : <GB size={18} />}
          </Button>
          {/* <NotificationsListComponent /> */}

          <Box>
            <Avatar
              sx={{ bgcolor: "primary.main", color: "primary.contrastText" }}
              component={"button"}
              aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              onClick={handleClick}
            >
              {app?.user?.first_name?.charAt(0) +
                app?.user?.last_name?.charAt(0)}
            </Avatar>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
