import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { useState } from "react";

export default function Text({
  error = null,
  register,
  id,
  type = "text",
  ...props
}) {
  const [elementType, setElementType] = useState(type);
  return (
    <Box position={"relative"}>
      <TextField
        size="small"
        fullWidth
        type={elementType}
        {...(register && { ...register(id) })}
        {...(error && {
          helperText: error,
          error: true,
        })}
        {...props}
      />
      {type === "password" && (
        <IconButton
          sx={{ position: "absolute", right: 0 }}
          onClick={() =>
            setElementType(elementType === "text" ? "password" : "text")
          }
        >
          {elementType === "text" ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )}
    </Box>
  );
}
