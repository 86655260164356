import { Container, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { readAbsenClasswise } from "../../api";
import convertToNepaliNumber from "../../utils/convertToNepaliNumber";
import LineChart from "./BarChart";

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const { data } = useQuery({
    queryKey: ["absent"],
    queryFn: readAbsenClasswise(),
  });

  return (
    <Container>
      <Grid2 container>
        <Grid2 sm={3} px={1}>
          <Stack
            component={Paper}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
          >
            <Typography variant="h6" color={"primary"}>
              {t("attendee")}
            </Typography>
            <Typography variant="h4" color={"grey.500"}>
              {i18n.language === "np" ? convertToNepaliNumber(100) : 100}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 sm={3} px={1}>
          <Stack
            component={Paper}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
          >
            <Typography variant="h6" color={"primary"}>
              {t("organization")}
            </Typography>
            <Typography variant="h4" color={"grey.500"}>
              {i18n.language === "np" ? convertToNepaliNumber(100) : 100}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 sm={3} px={1}>
          <Stack
            component={Paper}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
          >
            <Typography variant="h6" color={"primary"}>
              {t("department")}
            </Typography>
            <Typography variant="h4" color={"grey.500"}>
              {i18n.language === "np" ? convertToNepaliNumber(100) : 100}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 sm={3} px={1}>
          <Stack
            component={Paper}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
          >
            <Typography variant="h6" color={"primary"}>
              {t("todaysAttendance")}
            </Typography>
            <Typography variant="h4" color={"grey.500"}>
              {i18n.language === "np" ? convertToNepaliNumber(100) : 100}
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
      <Grid2 mt={4}>{data && <LineChart data={data} />}</Grid2>
    </Container>
  );
}
