import { array, boolean, object, string } from "yup";

export const loginSchema = object().shape({
  username: string().required("Username is required"),
  password: string().required("Password is required"),
});

export const fiscalYearSchema = () =>
  object().shape({
    title: string().required("Title is required"),
    isActive: boolean().required("Status is required"),
  });

export const organizationSchema = () =>
  object().shape({
    name: string().required("Name is required"),
    organizationType: object().shape({
      value: string().required("Organization Type is required"),
    }),
    phone: string().required("Phone is required"),
    address: string().required("Address is required"),
    device: array()
      .min(1, "At least one device is required")
      .required("Device is required")
      .of(
        object().shape({
          value: string().required("Device is required"),
        })
      ),
  });

export const departmentSchema = () => object().shape({});

export const shiftSchema = () =>
  object().shape({
    title: string().required("Title is required"),
    start_time: string().required("Start Time is required"),
    end_time: string().required("End Time is required"),
  });
export const classSchema = () =>
  object().shape({
    name: string().required("Name is required"),
    grade_text: object().shape({
      value: string().required("Grade is required"),
    }),
    section_text: string().required("Section is required"),
  });
export const organizationTypeSchema = () =>
  object().shape({
    name_np: string().required("Name is required"),
    name_en: string().required("Name is required"),
    attendeeTypes: string().required("Attendance Type is required"),
  });

export const deviceSchema = () =>
  object().shape({
    name: string().required("Name is required"),
    serial_number: string().required("Serial Number is required"),
  });

export const deviceConfigSchema = () => object().shape({});

export const userSchema = () =>
  object().shape({
    first_name: string().required("First Name is required"),
    last_name: string().required("Last Name is required"),
    email: string().required("Email is required"),
    phone: string().required("Phone is required"),
    role: object().shape({
      value: string().required("Role is required"),
    }),
    username: string().required("Username is required"),
    password: string().required("Password is required"),
  });

export const attendeeSchema = () => object().shape({});

export const holidaySchema = () => object().shape({});
