import {
  AccessTime,
  AddBusiness,
  Assessment,
  Celebration,
  CorporateFare,
  Dashboard,
  Devices,
  People,
  Schedule,
} from "@mui/icons-material";

const menu = {
  overview: [
    {
      key: "dashboard",
      path: "/",
      title: {
        i18n: "dashboard",
      },
      icon: Dashboard,
    },
    {
      key: "activityLog",
      path: "/activity-log",
      title: {
        i18n: "activityLog",
      },
      icon: Schedule,
    },
    {
      key: "attendees",
      path: "/attendees/all",
      title: {
        i18n: "attendees",
      },
      icon: People,
      permissions: ["view:allAttendee"],
    },
  ],
  reports: [
    {
      key: "monthlyReportSheet",
      path: "/reports/monthly-report/sheet/1",
      title: {
        i18n: "monthlyReportSheet",
      },
      icon: Assessment,
    },
    {
      key: "individualReport",
      path: "reports/individual-report",
      title: {
        i18n: "individualReport",
      },
      icon: Assessment,
    },
    {
      key: "absentList",
      path: "/reports/absent-list",
      title: {
        i18n: "absentList",
      },
      icon: Assessment,
    },
  ],
  management: [
    {
      key: "users",
      path: "/management/users/1",
      title: {
        i18n: "users",
      },
      icon: People,
      permissions: ["view:users"],
    },
    {
      key: "fiscalYear",
      path: "/management/fiscalYears/1",
      title: {
        i18n: "fiscalYears",
      },
      icon: AccessTime,
      permissions: ["view:fiscalYears"],
    },
    {
      key: "shift",
      path: "/management/shifts/1",
      title: {
        i18n: "shifts",
      },
      icon: AccessTime,
      permissions: ["view:shifts"],
    },
    {
      key: "organizationTypes",
      path: "/management/organization-types/1",
      title: {
        i18n: "orgTypes",
      },
      icon: AddBusiness,
      permissions: ["view:organizationTypes"],
    },
    {
      key: "organizations",
      path: "/management/organizations/1",
      title: {
        i18n: "organizations",
      },
      icon: CorporateFare,
      permissions: ["view:organizations"],
    },
    {
      key: "departments",
      path: "/management/departments/1",
      title: {
        i18n: "departments",
      },
      icon: CorporateFare,
      permissions: ["view:allDepartments"],
    },
    {
      key: "holidays",
      path: "/management/holidays/1",
      title: {
        i18n: "holidays",
      },
      icon: Celebration,
      permissions: ["view:holidays"],
    },
    {
      key: "classes",
      path: "/management/classes/1",
      title: {
        i18n: "classes",
      },
      icon: AddBusiness,
      permissions: ["view:classes"],
    },
    {
      key: "devices",
      path: "/management/devices/1",
      title: {
        i18n: "devices",
      },
      icon: Devices,
      permissions: ["view:devices"],
    },
  ],
};

export default menu;
