import { Loop } from "@mui/icons-material";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const filter = createFilterOptions();

export default function Select({
  error = null,
  setValue = null,
  id,
  options = [],
  label,
  getOptionLabel = null,
  defaultValue = null,
  onChange,
  isLoading = false,
  ...restProps
}) {
  const [selectedValue, setSelectedValue] = useState();

  const dV = useMemo(() => {
    if (restProps.multiple) {
      return options.filter((opt) => {
        return defaultValue?.includes(opt?.value);
      });
    } else {
      return options.find((opt) => defaultValue === opt?.value);
    }
  }, [options, defaultValue, restProps.multiple]);

  useEffect(() => {
    if (defaultValue) {
      setValue && setValue(id, dV);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, id, defaultValue]);

  return isLoading ? (
    <TextField
      fullWidth
      size="small"
      disabled
      placeholder="Loading..."
      InputProps={{
        endAdornment: <Loop className="tw-animate-spin" />,
      }}
    />
  ) : (
    <Autocomplete
      size="small"
      fullWidth
      clearOnEscape
      autoComplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      options={options}
      value={selectedValue}
      onChange={(event, newValue) => {
        if (newValue) {
          if (typeof newValue === "string") {
            onChange && onChange(newValue);
            setValue && setValue(id, newValue);
          }
          // else if (newValue && newValue.inputValue) {
          //   // Create a new value from the user input
          //   onChange && onChange(newValue.inputValue);
          //   setValue && setValue(id, newValue.inputValue);
          // }
          else {
            onChange && onChange(newValue);
            setValue && setValue(id, newValue);
          }
          setSelectedValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // const { inputValue } = params;
        // // Suggest the creation of a new value
        // const isExisting = options.some(
        //   (option) => inputValue === option.label
        // );
        // if (inputValue !== "" && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     label: `Add "${inputValue}"`,
        //   });
        // }

        return filtered;
      }}
      {...(getOptionLabel
        ? getOptionLabel
        : {
            getOptionLabel: (option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              // if (option.inputValue) {
              //   return option.inputValue;
              // }
              // Regular option
              return option?.label;
            },
          })}
      renderOption={(props, option) => (
        <li {...props} key={props.key}>
          {option?.label}
        </li>
      )}
      renderInput={(props) => (
        <TextField
          {...(error && {
            helperText: error,
            error: true,
          })}
          label={label}
          fullWidth
          sx={{ minWidth: "200px" }}
          {...props}
        />
      )}
      defaultValue={
        restProps?.multiple
          ? options.filter((option) => defaultValue?.includes(option?.value))
          : options.find((option) => option?.value === defaultValue) ||
            defaultValue
      }
      {...restProps}
    />
  );
}
