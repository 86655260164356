import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getHolidayMutationOptions } from "../../../api/mutationOptions";
import { getHolidayQueryOptions } from "../../../api/queryOptions";
import TableActions from "../../../components/TableActions";
import useApp from "../../../hooks/useApp";
import List from "../../../layouts/List";
export default function Holiday() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const app = useApp();

  /*
  name
  startDate
  endDate
  [organization]
  */

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("name"),
      },
      {
        accessorKey: "startDate",
        header: t("dateFrom"),
      },
      {
        accessorKey: "endDate",
        header: t("dateTo"),
      },
      ...(app?.user?.role === "admin"
        ? [
            {
              accessorKey: "organization",
              header: t("organization"),
              cell: ({ row }) => {
                return row.original.organization?.name;
              },
            },
          ]
        : []),
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(`/management/holiday/edit/${row.original.id}`),
              },
              delete: {
                mutationOptionFn: getHolidayMutationOptions,
                onClick: (mutate) =>
                  mutate &&
                  mutate({
                    mutationMode: "delete",
                    id: row.original.id,
                  }),
              },
            }}
          />
        ),
      },
    ],
    [t, navigate, app?.user?.role]
  );

  return (
    <Box p={2}>
      <List
        title="holidays"
        columns={columns}
        create={{
          title: "createHoliday",
          path: `/management/holiday/create${
            app?.user?.role === "admin"
              ? ""
              : `?organization=${app?.user?.organization?.id}`
          }`,
        }}
        queryOptionFn={getHolidayQueryOptions}
      />
    </Box>
  );
}
