export const USER_STATUS = {
  active: {
    translationKey: "active",
    color: "success.main",
  },
  inactive: {
    translationKey: "inactive",
    color: "error.main",
  },
  not_verified: {
    translationKey: "notVerified",
    color: "warning.main",
  },
  deleted_from_device: {
    translationKey: "deletedFromDevice",
    color: "error.main",
  },
};

export const ROLES = ["student", "teacher", "employee"];
